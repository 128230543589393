import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { CallBackProps, STATUS } from 'react-joyride';

import { AuthContainer } from './authContainer';
import { getUserReleasedFeatures, updateUserViewedFeatures } from '../services/user/userService';
import { ReleasedFeatureCodeType, ReleasedFeatureType } from '../types/ReleasedFeaturesTypes';

//@TODO: refactor
export const useNewFeature = () => {
  const { auth } = AuthContainer.useContainer();
  const [newFeaturesList, setNewFeaturesList] = useState<ReleasedFeatureType[]>([]);
  const [featuresListToShow, setFeaturesListToShow] = useState<ReleasedFeatureCodeType[]>([]);
  const [activeFeature, setActiveFeature] = useState<ReleasedFeatureCodeType | ''>('');

  const [isAppealEnabled, setIsAppealEnabled] = useState<boolean>(false);
  const [isAppealEnabledToRestart, setIsAppealEnabledToRestart] = useState<boolean>(false);
  const [isAuthorizedAgentEnabled, setIsAuthorizedAgentEnabled] = useState<boolean>(false);
  const [isAuthorizedAgentEnabledToRestart, setIsAuthorizedAgentEnabledToRestart] = useState<boolean>(false);

  const fetchNewFeatures = async () => {
    const releasedFeatures = await getUserReleasedFeatures();

    setFeaturesListToShow(releasedFeatures.filter(({ viewed }) => !viewed).map(({ code }) => code));
    setNewFeaturesList(releasedFeatures);
  };

  const setFeatureViewed = (viewedFeatureCode: ReleasedFeatureCodeType) => {
    updateUserViewedFeatures(viewedFeatureCode);
    setActiveFeature('');
    setFeaturesListToShow(state => state.filter(code => code !== viewedFeatureCode));
  };

  const isFeatureViewed = (viewedFeatureCode: ReleasedFeatureCodeType) =>
    !featuresListToShow.includes(viewedFeatureCode);

  const getFeatureByCode = useCallback(
    (featureCode: ReleasedFeatureCodeType) => newFeaturesList.find(item => item.code === featureCode),
    [newFeaturesList],
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetchNewFeatures();
    }
  }, [auth]);

  useEffect(() => {
    if (
      getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT) &&
      !getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT)?.viewed
    ) {
      setIsAuthorizedAgentEnabled(true);
    } else if (
      getFeatureByCode(ReleasedFeatureCodeType.APPEAL) &&
      !getFeatureByCode(ReleasedFeatureCodeType.APPEAL)?.viewed
    ) {
      setIsAppealEnabled(true);
      return;
    }
  }, [getFeatureByCode]);

  useEffect(() => {
    if (
      getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT)?.viewed ||
      getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT) === undefined
    ) {
      setIsAuthorizedAgentEnabledToRestart(true);
      return;
    } else if (getFeatureByCode(ReleasedFeatureCodeType.APPEAL)?.viewed) {
      setIsAppealEnabledToRestart(true);
      return;
    }
  }, [getFeatureByCode]);

  return {
    newFeaturesList,
    setNewFeaturesList,
    isAppealEnabled,
    isAuthorizedAgentEnabled,
    setIsAuthorizedAgentEnabled,
    setIsAppealEnabled,
    isAppealEnabledToRestart,
    isAuthorizedAgentEnabledToRestart,
    getFeatureByCode,
    isFeatureViewed,
    setFeatureViewed,
    activeFeature,
    setActiveFeature,
  };
};

// not the best name, move to /hooks ?
export const useNewFeatureManager = (guidedTourCode: ReleasedFeatureCodeType) => {
  const { setFeatureViewed, isFeatureViewed, setActiveFeature, activeFeature } = NewFeatureContainer.useContainer();
  const viewed = isFeatureViewed(guidedTourCode);

  const joyrideCallback = useCallback(
    (data?: CallBackProps) => {
      if (data) {
        const { status } = data;
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
          setFeatureViewed(guidedTourCode);
        }
      }
    },
    [setFeatureViewed, guidedTourCode],
  );

  useEffect(() => {
    !viewed && !activeFeature && setActiveFeature(guidedTourCode);

    return () => {
      activeFeature === guidedTourCode && setActiveFeature('');
    };
  }, [viewed, setActiveFeature, guidedTourCode, activeFeature]);

  return {
    joyrideCallback,
    isGuidedTourRunning: activeFeature === guidedTourCode,
    isGuidedTourViewed: viewed,
    setFeatureViewed,
  };
};

export const NewFeatureContainer = createContainer(useNewFeature);
