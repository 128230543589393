/* eslint-disable react/destructuring-assignment */
import React, { ReactNode, Component, ErrorInfo } from 'react';
import { Stack } from '@mui/material';

import { SnackbarUtils } from '../../utils';
import { BigidErrorPage } from '@bigid-ui/components';

interface ErrorBoundaryProps {
  children: ReactNode;
  silent?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    SnackbarUtils.error('Content cannot be displayed at the moment. Please refresh the page or try again later.');
    console.log(error, errorInfo);
  }

  render() {
    return this.state.hasError && !this.props.silent ? (
      <Stack alignContent="center" justifyContent="center" height="100vh">
        <BigidErrorPage
          title="Whoops, something went wrong."
          type="connectionLost"
          description="Please either refresh the page or return home to try again."
          onLinkClick={() => window.location.reload()}
        />
      </Stack>
    ) : (
      this.props.children
    );
  }
}
