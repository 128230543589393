import { omit } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { LanguageType } from '@consumer/state/languageContainer';
import { BigidGridQueryComponents } from '@bigid-ui/grid';

import {
  BasicSettingsStateType,
  BrandsGetType,
  BrandType,
  CountryType,
  IntegrationDetailsGetType,
  IntegrationEnablingState,
  IntegrationGetType,
  IntegrationUpdateType,
  PermissionGroupListType,
  PortalEditorGetDataType,
  PortalEditorPostDataType,
  PublishPortalPayloadType,
  PublishSubmitFormType,
  RequestGetSubmitFormsPreviewType,
  RequestSubmitFormType,
  PreferencesStepType,
  RequestSubmitTextType,
  RoleInfoResponseType,
  RoleInfoType,
  RoleType,
  SamlConfigurationType,
  SamlMappingType,
  TestConnectionResult,
  UseCaseGetType,
  UseCaseType,
  UserInfoType,
  RequestWorkflowCustomizationDefinitionGetType,
  UpdateWorkflowStageType,
  UpdateWorkflowType,
  RequestWorkflowDefinitionGetType,
  EmailTemplateDataGetType,
  UpdateEmailTemplateType,
  RequestWorkflowStageEmailType,
  TestEmailType,
  ManualCloseResolutionsGetType,
  UpdateManualCloseResolutionsType,
  CloseResolutionsGetType,
  UpdateAutoCloseResolutionsType,
  AddAutoCloseResolutionType,
  AutoCloseResolutionsGetType,
  AddManualCloseResolutionType,
  UserScreenState,
  UserPreferences,
  AllowOutsideRequestorsType,
  LocaleType,
  RequestorTypesGetType,
  RequestPasscodeType,
  JiraConfigurationFormType,
  UserApiKey,
  StageWebhooksGetType,
  WebhookTestResponseType,
  WebhookType,
  WebhooksPlaceholdersGetType,
  BaseSettingsRegulationType,
  OnboardingRegulation,
  UserGridPreferenceView,
  UseCasesType,
  RoleOptionsDataType,
  BigIdCloudConnectionUpdateType,
  BigIdCloudConnectionViewType,
  DueDateNotifications,
  DueDateNotificationsEmailTemplateFieldsType,
  DueDateNotificationsUpdate,
  UpdateStageEmailPayloadType,
  UserPasswordType,
} from '../types';
import { api } from './request';
import {
  AgentAccountType,
  AgentConnectivityType,
  AgentProfileType,
  GridFetchResponseType,
  OnboardingRegionsGetType,
  OnboardingRegionType,
  SettingsProfileType,
  SettingsUpdateLocales,
} from '../types/';
import { b64EncodeUnicode, normalizeFormikLinksToHyperlink, normalizePortalEditorGetData } from '../utils';
import { getBrandAuthorizedAgentWithEncodedTranslations } from '../utils/authorizedAgentUtils';

export const REQUEST_DEFINITIONS_AMOUNT = 6;

export const fetchBrands = async () => await api.get<BrandsGetType>('/brands').then(resolve => resolve.data);

export const addBrand = async () => await api.post<BrandType>('/brands').then(response => response.data);

export const deleteBrand = async (brandId: number) => await api.delete<BrandsGetType>(`/brands/${brandId}`);

export const patchBrand = async (id: number, formData: Pick<BrandType, 'siteName'>) =>
  await api.patch<BrandType>(`/brands/${id}`, formData).then(resolve => resolve.data);

export const fetchUserPreferences = async (gridName: string) =>
  await api.get<UserPreferences>(`/users/preferences/grids/${gridName}`).then(resolve => resolve.data);

export const patchUserPreferences = async (userPreferences: UserPreferences, gridName: string) =>
  await api
    .patch<UserGridPreferenceView>(`/users/preferences/grids/${gridName}`, userPreferences)
    .then(resolve => resolve.data);

export const createUserPreferenceView = async (view: UserGridPreferenceView, gridName: string) =>
  await api
    .post<UserGridPreferenceView>(`/users/preferences/grids/${gridName}/views`, { view })
    .then(resolve => resolve.data);

export const deleteUserPreferenceView = async (gridName: string, viewId: string | number) =>
  await api.delete(`/users/preferences/grids/${gridName}/views/${viewId}`).then(resolve => resolve.data);

export const deleteLogo = (brandId: number) => api.delete(`/brands/${brandId}/general-settings/logo`);

export const getLogoSize = async (brandId: number): Promise<{ width: number; height: number }> =>
  await api.get(`/brands/${brandId}/general-settings/logo-settings`);

export const loadUserScreenState = async () =>
  await api.get<UserScreenState>(`/screen/manage-users`).then(response => response.data);

export const loadAvailableRoles = async () =>
  await api.get<{ data: RoleType[] }>('/users/roles').then(response => response.data.data);

export const loadUsers = (search: string, { skip, limit }: BigidGridQueryComponents): Promise<GridFetchResponseType> =>
  api
    .get<GridFetchResponseType>('/users', {
      params: {
        search,
        skip,
        limit,
      },
    })
    .then(response => response.data);

export const loadUserApiKeys = (userId: number): Promise<UserApiKey[]> =>
  api.get<UserApiKey[]>(`/users/${userId}/api-keys`).then(response => response.data);

export const getRegulations = async (): Promise<BaseSettingsRegulationType[]> =>
  await api
    .get<{ regulations: BaseSettingsRegulationType[] }>('/regulations')
    .then(resolve => resolve.data.regulations);

export const patchRegulations = async (regulations: BaseSettingsRegulationType[] | OnboardingRegulation[]) =>
  await api.patch('/regulations', { regulations });

export const generateUserApiKey = async ({
  expirationDays,
  userId,
  name,
}: {
  expirationDays: number;
  userId: number;
  name: string;
}) =>
  await api
    .post(`/users/${userId}/api-keys`, {
      expirationDays,
      name,
    })
    .then(res => res.data);

export const deleteUserApiKey = async (userId: number, id: string) =>
  await api.delete<void>(`/users/${userId}/api-keys/${id}`);

export const loadReminderMessagingGroup = (dataSourceId: number): Promise<GridFetchResponseType> =>
  api
    .get<GridFetchResponseType>(`/users?groups=Collaborate&groups=All&dataSourceIds=${dataSourceId}`)
    .then(response => response.data);

export const loadUserById = async (id: number) =>
  await api.get<UserInfoType>(`/users/${id}`).then(response => response.data);

export const deleteUser = async (id: number) => await api.delete<void>(`/users/${id}`);

export const saveUser = async (id: number, { firstName, lastName, email, roles }: UserInfoType) =>
  await api.patch<void>(`/users/${id}`, {
    firstName,
    lastName,
    email,
    roleIds: roles.map(r => r.id),
  });

export const saveNewUserPassword = async (id: number, { currentPassword, password }: UserPasswordType) =>
  await api.patch<void>(`/users/${id}/password`, {
    currentPassword,
    password,
  });

export const inviteUser = async ({ firstName, lastName, email, roles }: UserInfoType) =>
  await api
    .post('/users', {
      firstName,
      lastName,
      email,
      roleIds: roles.map(r => r.id),
    })
    .then(res => res.data);

export const loadRoles = async (search: string, { skip, limit }: BigidGridQueryComponents) =>
  await api.get<GridFetchResponseType>('/roles', { params: { search, skip, limit } }).then(res => res.data);

export const loadRoleOptions = async () =>
  await api.get<RoleOptionsDataType>('/roles/role-options').then(res => res.data);

export const loadRoleById = async (id: string) =>
  await api.get<RoleInfoResponseType>(`/roles/${id}`).then(res => res.data);

export const getPermissionGroups = async () =>
  await api.get<PermissionGroupListType>('/permission-groups').then(res => res.data.permissionGroups);

export const patchPermissionGroup = async (
  id: number,
  data: {
    categories: { id: number; enabled: number }[];
  },
) => api.patch(`/permission-groups/${id}`, data);

export const createRole = async ({ name, permissionGroupId, scopeId }: RoleInfoType) =>
  await api.post('/roles', { name, permissionGroupId, scopeId }).then(res => res.data);

export const saveRole = async (id: string, { name, permissionGroupId, scopeId }: RoleInfoType) =>
  await api.patch(`/roles/${id}`, { name, permissionGroupId, scopeId }).then(res => res.data);

export const deleteRole = async (id: string) => await api.delete(`/roles/${id}`).then(res => res.data);

export const getPortalEditorData = (brandId: number): Promise<Omit<PortalEditorGetDataType, 'siteName'>> =>
  Promise.all([
    api.get(`/brands/${brandId}/general-settings`),
    api.get(`/brands/${brandId}/preferences?requestType=Preferences`),
    api.get(`/brands/${brandId}/preferences?requestType=OptOut`),
    api.get(`/brands/${brandId}/email-settings`),
  ]).then(([general, preferences, optOut, emailSettings]) =>
    normalizePortalEditorGetData(general.data, preferences.data, optOut.data, emailSettings.data),
  );

const getTextsWithEncodedTranslations = (requestSubmitTexts: RequestSubmitTextType[]) =>
  requestSubmitTexts.map(text => {
    const newText = { ...text };
    const translations = newText.translations;

    newText.translations = Object.keys(translations).reduce((obj, cur) => {
      const value = translations[cur as LanguageType]?.value;
      return { ...obj, [cur]: { value: value ? b64EncodeUnicode(value) : undefined } };
    }, {});
    return newText;
  });

const getStepsWithEncodedTranslations = (steps: PreferencesStepType[]) =>
  steps.map(step => {
    const newStep = { ...step };
    newStep.sections = newStep.sections.map(section => {
      const newSection = { ...section };
      const translations = newSection.translations;
      newSection.translations = Object.keys(translations).reduce((obj, cur) => {
        const subtitle = translations[cur as LanguageType]?.subtitle;
        return {
          ...obj,
          [cur]: {
            ...translations[cur as LanguageType],
            subtitle: subtitle ? b64EncodeUnicode(subtitle) : undefined,
          },
        };
      }, {});

      newSection.items = newSection.items.map(item => {
        const newItem = { ...item };
        const itemTranslations = newItem.translations;
        newItem.translations = Object.keys(itemTranslations).reduce((obj, cur) => {
          const label = itemTranslations[cur as LanguageType]?.label;
          const description = itemTranslations[cur as LanguageType]?.description;
          return {
            ...obj,
            [cur]: {
              ...itemTranslations[cur as LanguageType],
              label: label ? b64EncodeUnicode(label) : undefined,
              description: description ? b64EncodeUnicode(description) : undefined,
            },
          };
        }, {});
        return newItem;
      });

      return newSection;
    });
    return newStep;
  });

const sendUpdatePortalRequests = (
  brandId: number,
  {
    steps,
    optOuts,
    generalSettings,
    requestSubmitTexts,
    fields,
    newFields,
    deletedIds,
    formVerificationConfig,
    userProfiles,
    emailSettings,
    forms,
    updateRequestorTypesDto,
    updateBrandAuthorizedAgent,
  }: PublishPortalPayloadType,
) => {
  const omitedGeneralSettings = omit(generalSettings, ['texts']);
  const normalizedGeneralSettings = normalizeFormikLinksToHyperlink(omitedGeneralSettings as Record<string, string>, [
    'supportLink',
    'termsConditionsLink',
    'privacyPolicyLink',
  ]);

  const textsWithEncodedTranslations = getTextsWithEncodedTranslations(requestSubmitTexts);
  const stepsWithEncodedTranslations = getStepsWithEncodedTranslations(steps);
  const optOutWithEncodedTranslations = getStepsWithEncodedTranslations(optOuts);
  const brandAuthorizedAgentWithEncodedTranslations =
    getBrandAuthorizedAgentWithEncodedTranslations(updateBrandAuthorizedAgent);

  return [
    api.post(`/consumer/brands/${brandId}/editor/actions/publish`, {
      fields,
      newFields,
      deletedIds,
      userProfiles,
      forms,
      generalSettings: normalizedGeneralSettings,
      texts: textsWithEncodedTranslations,
      formVerificationConfig,
      preferences: { steps: stepsWithEncodedTranslations },
      optOuts: { steps: optOutWithEncodedTranslations },
      emailSettings,
      updateRequestorTypesDto,
      updateBrandAuthorizedAgent: brandAuthorizedAgentWithEncodedTranslations,
    }),
  ];
};

export const postPortalEditorData = (
  brandId: number,
  { shouldDeleteLogo, companyLogo, data }: PortalEditorPostDataType,
): Promise<RequestSubmitFormType[]> => {
  if (shouldDeleteLogo) {
    return Promise.all([deleteLogo(brandId), ...sendUpdatePortalRequests(brandId, data)]).then(
      results => results[results.length - 1].data,
    );
  }

  return Promise.all(
    companyLogo
      ? [
          api.post<void>(`/brands/${brandId}/general-settings/logo`, companyLogo),
          ...sendUpdatePortalRequests(brandId, data),
        ]
      : sendUpdatePortalRequests(brandId, data),
  ).then(results => results[results.length - 1].data);
};

export const getOnboardingRegions = async (search = ''): Promise<OnboardingRegionsGetType> =>
  await api
    .get('/regions', {
      params: {
        search,
      },
    })
    .then(res => res.data);

export const putOnboardingRegions = async (regions: { headquarters: string; regions: OnboardingRegionType[] }) =>
  await api.put('/regions', { ...regions });

export const getUseCasesData = async (brandId: number, allowedOnly = false): Promise<UseCaseGetType[]> =>
  await api
    .get<{ definitions: UseCaseGetType[] }>(`/brands/${brandId}/request-definitions?allowedOnly=${allowedOnly}`)
    .then(res => res.data.definitions);

export const getUseCasesType = async (brandId: number): Promise<UseCasesType[]> =>
  await api.get<{ types: UseCasesType[] }>(`/brands/${brandId}/request-definition-types`).then(res => res.data.types);

export const getRequestorTypesData = async (brandId: number): Promise<RequestorTypesGetType> =>
  await api.get<RequestorTypesGetType>(`/brands/${brandId}/requestor-types`).then(res => res.data);

export const putUseCases = async (brandId: number, definitions: UseCaseType[]) =>
  await api.put(`/brands/${brandId}/request-definitions`, { definitions });

export const updateLocales = async (locales: SettingsUpdateLocales[]) =>
  await api.put('/locales', {
    locales,
  });

export const updateUserProfiles = async (profiles: SettingsProfileType[]) =>
  await api.put('/user-profiles', { profiles });

export const updateUserProfile = (profile: SettingsProfileType) => api.patch(`/user-profiles/${profile.id}`, profile);

export const createUserProfile = (profile: SettingsProfileType) => api.post('/user-profiles', profile);

export const deleteUserProfile = (id: number) => api.delete(`/user-profiles/${id}`);

export const getAgentProfiles = (): Promise<AgentProfileType[]> =>
  api.get('/agent-user-profiles').then(({ data }) => data.profiles);

export const fetchConsumerAppUrlAll = async (brandId: number): Promise<RequestGetSubmitFormsPreviewType> =>
  await api.get<RequestGetSubmitFormsPreviewType>(`/brands/${brandId}/publish-page`).then(({ data }) => data);

export const publishConsumerApp = async (): Promise<PublishSubmitFormType> =>
  await api.post<PublishSubmitFormType>('/request-submit-forms/actions/publish').then(({ data }) => data);

export const fetchEnabledLanguages = (tenant: string) =>
  api.get<{ locales: LocaleType[] }>(`${tenant}/supported-locales`).then(res => res.data.locales);

export const getAgentAccount = async (): Promise<AgentAccountType> =>
  await api.get('/onboarding/agent/account').then(({ data }) => data);

export const postAgentAccount = async (): Promise<AgentAccountType> =>
  await api.post('/onboarding/agent/account').then(({ data }) => data);

export const getAgentConnectivity = (): Promise<AgentConnectivityType> =>
  api.get('/onboarding/agent').then(({ data }) => data);

export const postTestBigIdCloudConnection = async (data: BigIdCloudConnectionUpdateType): Promise<boolean> =>
  await api.post('/bigid-cloud-connection/credentials/save-connection', data).then(({ data }) => data);

export const getBigIdCloudConnectionCredentials = async (): Promise<BigIdCloudConnectionViewType> =>
  await api.get('/bigid-cloud-connection/credentials').then(({ data }) => data);

export const getCountries = (): Promise<CountryType[]> =>
  api
    .get<{ countries: CountryType[] }>('/countries', {
      params: {
        locale: 'en',
      },
    })
    .then(({ data }) => data.countries);

export const getSamlConfigurations = (): Promise<{ config: SamlConfigurationType; samlMappings: SamlMappingType[] }> =>
  api.get('/saml-configurations').then(response => {
    const { samlMappings = [], ...config } = response.data.data[0] || {};
    return {
      config,
      samlMappings,
    };
  });

export const createSamlConfiguration = (data: SamlConfigurationType) =>
  api.post<SamlConfigurationType>('/saml-configurations', data).then(response => response.data);

export const updateSamlConfiguration = (id: number, data: SamlConfigurationType) =>
  api.patch<SamlConfigurationType>(`/saml-configurations/${id}`, data).then(response => response.data);

export const createSamlMapping = (data: SamlMappingType) =>
  api.post<SamlMappingType>('/saml-mappings', data).then(response => response.data);

export const updateSamlMapping = (id: number, data: SamlMappingType) =>
  api.patch<SamlMappingType>(`/saml-mappings/${id}`, data).then(response => response.data);

export const deleteSamlMapping = (id: number) =>
  api.delete<SamlMappingType>(`/saml-mappings/${id}`).then(response => response.data);

export const getSamlMappingsInitialSetup = () =>
  api.get<SamlMappingType[]>('/saml-mappings/initial-setup').then(response => response.data);

export const getBasicSettingsState = async () =>
  api.get<BasicSettingsStateType>('/basic-settings').then(({ data }) => data);

export const getIntegrations = async (): Promise<IntegrationGetType[]> =>
  await api.get('/integrations').then(resolve => resolve.data);

export const getIntegrationById = async (id: number): Promise<IntegrationDetailsGetType> =>
  await api.get(`/integrations/${id}/details`).then(resolve => resolve.data);

export const updateIntegration = async (id: number, integration: IntegrationUpdateType) =>
  await api.put(`/integrations/${id}/details`, integration);

export const updateIntegrationEnablingState = async (integrations: IntegrationEnablingState[]) =>
  await api.put(`/integrations/enabling-state`, integrations);

export const testIntegrationConnection = async (
  id: number,
  integration?: IntegrationUpdateType,
): Promise<TestConnectionResult> =>
  await api.post(`/integrations/${id}/test-connection`, integration).then(resolve => resolve.data);

export const fetchWorkflowCustomizationDefinitions = async () =>
  await api.get<RequestWorkflowCustomizationDefinitionGetType>(`/request-definitions`).then(response => ({
    totalCount: response.data.definitions.length || REQUEST_DEFINITIONS_AMOUNT,
    data: response.data.definitions,
  }));

export const updateWorkflowStage = async (id: number, params: UpdateWorkflowStageType) =>
  await api.patch(`/request-stages/${id}`, params);

export const updateWorkflow = async (
  requestDefinitionId: number,
  params: UpdateWorkflowType,
): Promise<RequestWorkflowDefinitionGetType> =>
  await api.patch(`/request-definitions/${requestDefinitionId}`, params).then(resolve => resolve.data);

export const updateStageEmail = async (
  id: string | number,
  params: UpdateStageEmailPayloadType,
): Promise<RequestWorkflowStageEmailType> =>
  await api.patch(`/request-stage-emails/${id}`, params).then(resolve => resolve.data);

export const updateActionWorkflow = async (actionId: string | number, enabled: boolean) =>
  await api.patch(`/request-stage-actions/${actionId}`, { enabled }).then(resolve => resolve.data);

export const fetchEmailTemplate = async (id?: number) =>
  await api.get<EmailTemplateDataGetType>(`/email-templates/${id}`).then(response => response.data);

export const fetchDueDateNotifications = async (id: number) =>
  await api
    .get<DueDateNotifications>(`/request-definitions/${id}/due-date-notifications`)
    .then(response => response.data);

export const updateDueDateNotifications = async (id: number, data: DueDateNotificationsUpdate) =>
  await api.patch<DueDateNotifications>(`/request-definitions/${id}/due-date-notifications`, data);

export const postTestEmailDueDateNotifications = async (
  id: number,
  data: DueDateNotificationsEmailTemplateFieldsType,
) => await api.post<DueDateNotifications>(`/request-definitions/${id}/due-date-notifications/actions/preview`, data);

export const updateEmailTemplate = async (
  id: string | number,
  params: UpdateEmailTemplateType,
): Promise<UpdateEmailTemplateType> => await api.patch(`/email-templates/${id}`, params).then(resolve => resolve.data);

export const postTestEmail = async (id: number, params: TestEmailType) =>
  await api.post(`/email-templates/${id}/actions/preview`, params);

// Manual close resoulution
export const fetchManualCloseResolutions = async (id: number) =>
  await api
    .get<CloseResolutionsGetType>(`/request-stages/${id}/request-close-resolutions`)
    .then(response => response.data.resolutions);

export const addManualCloseResolution = async (
  params: AddManualCloseResolutionType,
): Promise<ManualCloseResolutionsGetType> =>
  await api.post(`/request-close-resolutions`, params).then(response => response.data);

export const deleteManualCloseResolutions = async (id: string | number): Promise<ManualCloseResolutionsGetType> =>
  await api.delete(`/request-close-resolutions/${id}`).then(resolve => resolve.data);

export const fetchWebhooksAutocomplete = async () =>
  await api.get<WebhooksPlaceholdersGetType>(`/webhook-placeholders`).then(response => response.data.placeholders);

export const fetchStageWebhooks = async (stageId: number) =>
  await api.get<StageWebhooksGetType>(`/request-stages/${stageId}/webhooks`).then(response => response.data.webhooks);

export const updateWebhook = async (params: WebhookType): Promise<WebhookType> =>
  await api.patch(`/webhooks/${params.id}`, params).then(resolve => resolve.data);

export const addWebhook = async (stageId: number, params: WebhookType): Promise<WebhookType> =>
  await api.post(`/request-stages/${stageId}/webhooks`, params).then(response => response.data);

export const deleteWebhook = async (webhookId: string | number): Promise<WebhookType> =>
  await api.delete(`/webhooks/${webhookId}`).then(resolve => resolve.data);

export const postTestWebhook = async (params: WebhookType): Promise<WebhookTestResponseType> =>
  await api.post(`/webhook-test`, { webhook: params, webhookContext: {} }).then(resolve => resolve.data);

export const updateManualCloseResolutions = async (
  id: string | number,
  params: UpdateManualCloseResolutionsType,
): Promise<ManualCloseResolutionsGetType> =>
  await api.patch(`/request-close-resolutions/${id}`, params).then(resolve => resolve.data);

export const setDefaultManualCloseResolutions = async (
  id: string | number,
  name: string,
): Promise<ManualCloseResolutionsGetType> =>
  await api.patch(`/request-close-resolutions/${id}`, { defaultForStage: true, name }).then(resolve => resolve.data);

// Auto close resolution
export const fetchAutoCloseResolutions = async (id: number) =>
  await api
    .get<CloseResolutionsGetType>(`/consumer-verification-resolutions?requestId=${id}`)
    .then(response => response.data.resolutions);

export const addAutoCloseResolution = async (
  params: AddAutoCloseResolutionType,
): Promise<AutoCloseResolutionsGetType> =>
  await api.post(`/consumer-verification-resolutions`, params).then(response => response.data);

export const deleteAutoCloseResolutions = async (id: string | number): Promise<RequestWorkflowStageEmailType> =>
  await api.delete(`/consumer-verification-resolutions/${id}`).then(resolve => resolve.data);

export const updateAutoCloseResolutions = async (
  id: string | number,
  params: UpdateAutoCloseResolutionsType,
): Promise<AutoCloseResolutionsGetType> =>
  await api.patch(`/consumer-verification-resolutions/${id}`, params).then(resolve => resolve.data);

export const getAllowOutsideRequestors = async (requestType?: number): Promise<AllowOutsideRequestorsType> =>
  api
    .get<AllowOutsideRequestorsType>(`/request-definitions/${requestType}/allow-outside-requestors`)
    .then(({ data }) => data);

export const patchAllowOutsideRequestors = async (
  requestType: number,
  allowOutsideRequestors: AllowOutsideRequestorsType,
) => {
  await api.patch(`/request-definitions/${requestType}/allow-outside-requestors`, allowOutsideRequestors);
};

export const getPasscodes = async () => await api.get<RequestPasscodeType>('/passcodes').then(res => res.data);

export const updatePasscodes = async (data: RequestPasscodeType): Promise<RequestPasscodeType> =>
  await api.patch(`/passcodes`, data).then(resolve => resolve.data);

export const getJiraConfigurations = async () => await api.get(`/jira-configurations`).then(({ data }) => data);

export const getJiraConfigurationById = async (id: string) =>
  await api.get(`/jira-configurations/${id}`).then(({ data }) => data);

export const postJiraConfigurations = async (params: unknown) =>
  await api.post(`/jira-configurations`, params).then(({ data }) => data);

export const updateJiraConfiguration = async (id: string, params: unknown) =>
  await api.patch(`/jira-configurations/${id}`, params).then(({ data }) => data);

export const deleteJiraConfigurations = async (ids: string[]) => {
  const promises = ids.map(id => api.delete(`/jira-configurations/${id}`));

  return await Promise.all(promises);
};

export const getJiraProjects = async (
  params: Omit<JiraConfigurationFormType, 'projectName'>,
  pagination: { offset: number; limit: number } = { offset: 0, limit: 50000 },
) => await api.post('/jira-configuration-projects-search', { ...params, ...pagination }).then(({ data }) => data);

export const testJiraConnection = async (params: JiraConfigurationFormType) =>
  await api.post('/jira-test-connection', params).then(({ data }) => data);

export const getAvailableVendorEngagements = async (requestDefinitionId: number | string) =>
  await api
    .get<{
      engagements: Array<{
        groupEmail: string;
        hasAssets: boolean;
        hasDataSources: boolean;
        id: number;
        mappedDataSourceIds: number[];
        name: string;
        vendorName: string;
        vendorType: string;
      }>;
    }>(`/request-definitions/${requestDefinitionId}/vendor-engagements`)
    .then(({ data }) => data.engagements);
