import { BigidMeFilterType } from '../components/MeFilterToolbar';

import {
  FileType,
  GridRowType,
  ManualRequestResolutionsType,
  RegulationType,
  UploadedFileType,
  VerificationProviderType,
} from '.';
import { JiraIssueBasic } from './JiraTypes';

/**
 *
 * RequestType Order is important !!!
 * check const RequestTypeNaming = ['View', 'Delete', 'Update', 'Preferences', 'OptOut', 'Appeal'];
 */
export enum RequestType {
  VIEW = 'View',
  DELETE = 'Delete',
  UPDATE = 'Update',
  PREFERENCES = 'Preferences',
  OPT_OUT = 'OptOut',
  APPEAL = 'Appeal',
}

export enum RequestKeyType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum DeliveryMethod {
  EMAIL = 'EMAIL',
  PHYSICAL_MAIL = 'PHYSICAL_MAIL',
}

export enum TrendRequestType {
  VIEW = 'view',
  DELETE = 'delete',
  UPDATE = 'update',
  PREFERENCES = 'preferences',
  OPT_OUT = 'optOut',
  APPEAL = 'appeal',
}

export enum RequestStatusType {
  OVERDUE = 'Overdue',
  ALMOST_OVERDUE = 'AboutOverdue',
  IN_PROGRESS = 'InProgress',
  NA = 'NA',
}

export enum TrendRequestStatusType {
  OVERDUE = 'overdue',
  ALMOST_OVERDUE = 'aboutOverdue',
  IN_PROGRESS = 'inProgress',
}

export enum RequestRejectReasonType {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum CollectingDsarType {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum ProcessingStageType {
  CONFIRM = 'Confirm',
  VERIFY = 'Verify',
  APPROVE = 'Approve',
  COLLECT = 'Collect',
  REVIEW = 'Review',
  UPDATE = 'Update',
  COMPLETE = 'Complete',
}

export enum RequestStageType {
  CONFIRM = 'confirm',
  VERIFY = 'verify',
  APPROVE = 'approve',
  COLLECT = 'collect',
  REVIEW = 'review',
  UPDATE = 'update',
  COMPLETE = 'complete',
}

export enum RequestStateTabsStepType {
  SUCCEEDED = 'Succeeded',
  CURRENT = 'Current',
  DISABLED = 'Disabled',
  FAILED = 'Failed',
}

export enum StatusCardType {
  NONE = 'none',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAIL = 'fail',
  PROGRESS = 'progress',
  NO_DATA = 'no_data',
  WARNING = 'warning',
  ADD = 'add',
}

export interface RequestDataType extends GridRowType {
  verificationMethod: string;
  userType: string;
  useEditFinished: boolean;
  userEditMailSentDate: string;
  customReport?: boolean;
  reportDownloaded?: boolean;
  reportDownloadDate?: string;
  reportDownloadedByAdmin?: boolean;
  reportTemplateId?: number;
  verificationProvider?: VerificationProviderType;
}

export interface RequestResponseType {
  totalCount: number;
  data: RequestDataType[];
}

export enum CustomerFieldType {
  USER_FIRSTNAME = 'userFirstName',
  USER_LASTNAME = 'userLastName',
  USER_EMAIL = 'userEmail',
  USER_PHONE = 'userPhone',
  USER_PHOTO = 'userPhoto',
  USER_COUNTRY = 'userCountry',
  USER_COUNTRY_DIVISION = 'userCountryDivision',
  USER_TYPE = 'userType',
  DELIVERY_METHOD = 'deliveryMethod',
  USER_DATE_OF_BIRTH = 'userDateOfBirth',
  APPEAL_TEXT = 'appealText',
  ORIGINAL_REQUEST_ID = 'originalRequestId',
  USER_COMMENT = 'userComment',
  USER_NATIONAL_ID = 'userNationalId',
}

export enum ValidationPhotoType {
  NATIONAL_STATE_ID = 'National/StateID',
  DRIVING_LICENSE = 'Driving License',
  PASSPORT = 'Passport',
}

export interface UserFilesType {
  uploadId: string;
  type: ValidationPhotoType;
  filename?: string;
}

export interface CustomerDetailsType {
  name: CustomerFieldType;
  value: string;
  originalValue: string;
  label: string;
  masked: boolean;
}

export type CustomerPreferenceType = {
  name: string;
  value?: boolean;
};

export interface CustomerDetailsWithFilesType {
  attributes: CustomerDetailsType[];
  fileIds: UserFilesType[];
  files: string;
  agentAttributes: CustomerDetailsType[];
  agentFileIds: UserFilesType[];
  agentFiles: string;
}

export enum SystemsStateType {
  COMPLETED = 'Completed',
  NO_DATA = 'NoData',
  PROGRESS = 'InProgress',
  FAILED = 'Failed',
}

export enum DSARStateType {
  PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  ABORTED = 'Aborted',
  TIMEOUT = 'TimedOut',
}

export interface SystemsType {
  name: string;
  type: string;
  state: SystemsStateType;
  scannedObjects: number;
  failedObjects: number;
  recordsFound: number;
}

export interface StageType {
  tabKey: number;
  type: ProcessingStageType;
  state: RequestStateTabsStepType;
  stageContent: ProcessingStageType;
}

export interface StageResponseType {
  name: ProcessingStageType;
  status: RequestStateTabsStepType;
}

export type StagesResponseType = {
  stages: StageResponseType[];
};

export enum ActionsType {
  ABORT = 'ABORT',
  NEXT = 'NEXT',
  BACK = 'BACK',
  CLOSE = 'CLOSE',
  RETRY = 'RETRY',
  DONE = 'DONE',
  IMPORT = 'IMPORT',
  REFRESH_DATA_SOURCES = 'REFRESH_DATA_SOURCES',
  PREVIEW_REPORT = 'PREVIEW_REPORT',
  DATA_SOURCE_DONE = 'DATA_SOURCE_DONE',
  DATA_SOURCE_EXCLUDE = 'DATA_SOURCE_EXCLUDE',
  DATA_SOURCE_INCLUDE = 'DATA_SOURCE_INCLUDE',
  DATA_SOURCE_AUTO_DELETE = 'DATA_SOURCE_AUTO_DELETE',
  DATA_SOURCE_MANUAL_DELETE = 'DATA_SOURCE_MANUAL_DELETE',
  REMIND_COLLABORATORS = 'REMIND_COLLABORATORS',
  DOWNLOAD_CSV = 'DOWNLOAD_CSV',
  UPLOAD_REPORT = 'UPLOAD_REPORT',
  VERIFY = 'VERIFY',
  CONFIRM = 'CONFIRM',
  ADD_DATA_SOURCE_NOTE = 'ADD_DATA_SOURCE_NOTE',
  DELETE_DATA_SOURCE_NOTE = 'DELETE_DATA_SOURCE_NOTE',
  DELETE_NOTE = 'DELETE_NOTE',
  UPDATE_NOTE = 'UPDATE_NOTE',
  CREATE_NOTE = 'CREATE_NOTE',
  READ_NOTE = 'READ_NOTE',
  EXCLUDE = 'EXCLUDE',
  MASK = 'MASK',
  UNMASK = 'UNMASK',
  EDIT = 'EDIT',
  RETRY_SYSTEM = 'RETRY_SYSTEM',
  USER_COMMENT_DONE = 'USER_COMMENT_DONE',
  MARK_COMPLETED = 'MARK_COMPLETED',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  DELETE_ACTIVITY_LOG = 'DELETE_ACTIVITY_LOG',
  UPDATE_ACTIVITY_LOG = 'UPDATE_ACTIVITY_LOG',
  READ_ACTIVITY_LOG = 'READ_ACTIVITY_LOG',
  VIEW_REQUESTOR_COMMUNICATIONS = 'VIEW_REQUESTOR_COMMUNICATIONS',
  CONTACT_REQUESTOR = 'CONTACT_REQUESTOR',
  APPROVE = 'APPROVE',
  ADD_BRAND = 'ADD_BRAND',
  UPDATE_BRAND = 'UPDATE_BRAND',
  DELETE_BRAND = 'DELETE_BRAND',
  PURPOSE = 'PURPOSE',
  ADD_PURPOSE = 'ADD_PURPOSE',
  CATEGORY = 'CATEGORY',
  ADD_CATEGORY = 'ADD_CATEGORY',
  FRIENDLY_NAME = 'FRIENDLY_NAME',
  ADD_FRIENDLY_NAME = 'ADD_FRIENDLY_NAME',
  SHOW_ATTRIBUTES = 'SHOW_ATTRIBUTES',
  SHOW_REPORT_TEMPLATES = 'SHOW_REPORT_TEMPLATES',
  SHOW_USERS = 'SHOW_USERS',
  SHOW_PROFILES = 'SHOW_PROFILES',
  SHOW_REQUEST_ATTRIBUTES = 'SHOW_REQUEST_ATTRIBUTES',
  SHOW_PREFERENCES = 'SHOW_PREFERENCES',
  RETRY_AUTO_VERIFICATION = 'RETRY_AUTO_VERIFICATION',
  RETRY_CONFIRMATION_EMAIL = 'RETRY_CONFIRMATION_EMAIL',
  RETRY_CORRECTIONS_EMAIL = 'RETRY_CORRECTIONS_EMAIL',
  VERIFY_AGENT = 'VERIFY_AGENT',
  DATA_SOURCE_ADD_JIRA_ISSUE = 'DATA_SOURCE_ADD_JIRA_ISSUE',
  DATA_SOURCE_SEND_EMAIL = 'DATA_SOURCE_SEND_EMAIL',
  DATA_SOURCE_DELETE_JIRA_ISSUE = 'DATA_SOURCE_DELETE_JIRA_ISSUE',
  DATA_SOURCE_MARK_MANUAL_COLLECT_DONE = 'DATA_SOURCE_MARK_MANUAL_COLLECT_DONE',
}

export interface SystemActionsType {
  [ActionsType.RETRY_SYSTEM]?: boolean;
  [ActionsType.DATA_SOURCE_ADD_JIRA_ISSUE]?: boolean;
}

export interface CollectingSystemType {
  id: number;
  name: string;
  type: string;
  state: SystemsStateType;
  recordsFoundCount: number;
  changed?: boolean;
  actions: SystemActionsType;
  manualCollectDone: boolean;
  jiraIssue?: { key: string; url: string };
}

export enum StageStatusType {
  INACTIVE = 'INACTIVE',
  NO_DATA_FOUND = 'NO_DATA_FOUND',
  WAITING_CONSUMER = 'WAITING_CONSUMER',
  WAITING_CONFIRMATION = 'WAITING_CONFIRMATION',
  CORRECTIONS_EMAIL_FAILED = 'CORRECTIONS_EMAIL_FAILED',
  COMPLETED = 'COMPLETED',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  APPROVED = 'APPROVED',
}

export enum DataSourceStatusType {
  WAITING_COLLABORATOR = 'WAITING_COLLABORATOR',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  EXCLUDED = 'EXCLUDED',
}

export enum DiplicatesResolutionMode {
  SAVE_NEW = 'SAVE_NEW',
  OVERRIDE = 'OVERRIDE',
}
export interface DataSourceType {
  id: number;
  name: string;
  type: string;
  status: DataSourceStatusType;
  changed?: string;
}

export type RequestActionsType = Partial<Record<ActionsType, boolean>>;

export type ApproveDataStateType = {
  actions: {
    [ActionsType.APPROVE]?: boolean;
    [ActionsType.CLOSE]?: boolean;
  };
  closeEmail?: EmailStatus;
};

export interface ReviewDataStateType {
  actions: ReviewDataActionsType;
  totalDataSources: number;
  completedDataSources: number;
  status: StageStatusType;
  dataSources: DataSourceType[];
  closeEmail?: EmailStatus;
}

export interface ReviewDataActionsType {
  [ActionsType.IMPORT]?: boolean;
  [ActionsType.CLOSE]?: boolean;
  [ActionsType.PREVIEW_REPORT]?: boolean;
  [ActionsType.DONE]?: boolean;
  [ActionsType.NEXT]?: boolean;
  [ActionsType.EXCLUDE]?: boolean;
  [ActionsType.MASK]?: boolean;
  [ActionsType.UNMASK]?: boolean;
  [ActionsType.EDIT]?: boolean;
}

export interface CollectingAutoDetailsType {
  dsarState: DSARStateType;
  systemsScannedCount: number;
  totalRecordsCount: number;
  systemsCount: number;
}

export interface CollectingDataDetailsResponseType {
  // true | false - is enabled | disabled, no action - not to show
  actions: {
    [ActionsType.DONE]?: boolean;
    [ActionsType.ABORT]?: boolean;
    [ActionsType.NEXT]?: boolean;
    [ActionsType.CLOSE]?: boolean;
    [ActionsType.RETRY]?: boolean;
    [ActionsType.IMPORT]?: boolean;
    [ActionsType.REFRESH_DATA_SOURCES]?: boolean;
  };
  auto: CollectingAutoDetailsType;
  manual: {
    totalRecordsCount: number;
    systemsCount: number;
  };
  upgradeEnabled: boolean;
  closeEmail?: EmailStatus;
}

export enum DeleteDetailsType {
  DELETED = 'Deleted',
  SENT_TO_VENDOR = 'Sent to vendor',
  APPROVED_BY_VENDOR = 'Approved by vendor',
  REGULATORY_ISSUE = 'Regulatory issue',
  NO_DATA_FOUND = 'No data found',
  OTHER_RESOLUTION = 'Other resolution',
  MISSING_GROUP_EMAIL = 'Missing group email',
}

export interface UpdateDataSourceType {
  actions: {
    [ActionsType.DATA_SOURCE_DONE]?: boolean;
    [ActionsType.REMIND_COLLABORATORS]?: boolean;
    [ActionsType.DOWNLOAD_CSV]?: boolean;
  };
  id: number;
  name: string;
  status: DataSourceStatusType;
  note?: NoteType;
  type: string;
  autoModification: {
    systemLink: string;
    autoDeleteSupported: boolean;
  };
  changed?: boolean;
  jiraIssue?: JiraIssueBasic;
  recordsCount: number;
  statusDate: Date;
  details: DeleteDetailsType;
}

export interface UpdateStageResponseType {
  // true | false - is enabled | disabled, no action - not to show
  actions: {
    [ActionsType.CLOSE]?: boolean;
    [ActionsType.NEXT]?: boolean;
    [ActionsType.DONE]?: boolean;
    [ActionsType.DATA_SOURCE_DONE]?: boolean;
    [ActionsType.REMIND_COLLABORATORS]?: boolean;
    [ActionsType.DOWNLOAD_CSV]?: boolean;
    [ActionsType.USER_COMMENT_DONE]?: boolean;
    [ActionsType.SHOW_PREFERENCES]?: boolean;
    [ActionsType.RETRY_CORRECTIONS_EMAIL]?: boolean;
  };
  completedDataSources: number;
  totalDataSources: number;
  dataSources: UpdateDataSourceType[];
  status:
    | StageStatusType.INACTIVE
    | StageStatusType.WAITING_CONFIRMATION
    | StageStatusType.WAITING_CONSUMER
    | StageStatusType.CORRECTIONS_EMAIL_FAILED
    | StageStatusType.COMPLETED;
  dataSourcesStatus: DataSourceStatusType;
  simpleUpdate: boolean;
  completedUserPreferences: number;
  totalUserPreferences: number;
  notFoundConsentExists: boolean;
  userComment: string;
  correctionsEmail?: EmailStatus;
  closeEmail?: EmailStatus;
}

export type StageDataType = {
  actions: RequestActionsType;
  closeEmail?: EmailStatus;
};

export enum VerifyStageStatus {
  AUTO_VERIFIED = 'AUTO_VERIFIED',
  MANUALLY_VERIFIED = 'MANUALLY_VERIFIED',
  AUTO_VERIFYING = 'AUTO_VERIFYING',
  MANUALLY_VERIFYING = 'MANUALLY_VERIFYING',
  NO_AUTOMATED_DATA_SOURCES = 'NO_AUTOMATED_DATA_SOURCES',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  UNVERIFIED = 'UNVERIFIED',
  AUTO_VERIFICATION_ERROR = 'AUTO_VERIFICATION_ERROR',
  AGENT_WITH_AUTO_VERIFYING = 'AGENT_WITH_AUTO_VERIFYING',
  AGENT_WITH_MANUALLY_VERIFYING = 'AGENT_WITH_MANUALLY_VERIFYING',
  NA = 'NA',
}

export enum ConfirmStageStatus {
  WAITING_MANUAL_CONFIRMATION = 'WAITING_MANUAL_CONFIRMATION',
  WAITING_EMAIL_CONFIRMATION = 'WAITING_EMAIL_CONFIRMATION',
  CONFIRMED_MANUALLY = 'CONFIRMED_MANUALLY',
  CONFIRMED = 'CONFIRMED',
  CONFIRMATION_EMAIL_FAILED = 'CONFIRMATION_EMAIL_FAILED',
  UNCONFIRMED = '',
}

export type EmailStatus = {
  requestId: string;
  emailId: string;
  emailCode: string;
  timestamp: string;
  startSendingDate: string;
  finishSendingDate: string;
  details: string;
  status: EmailStatusType;
  retryRequired: boolean;
};

export type ConfirmationStageDataType = {
  confirmed: boolean;
  confirmationDate: string;
  confirmedByUserName: string;
  status: ConfirmStageStatus;
  confirmationEmail?: EmailStatus;
  correctionsEmail?: EmailStatus;
  closeEmail?: EmailStatus;
} & StageDataType;

export type VerifyStageDataType = {
  status: VerifyStageStatus;
  automatedDataSourcesCount?: number;
  verifiedDate: string;
  verifiedByUserName: string;
  verificationResolution: string;
  verificationData?: string;
} & StageDataType;

export type CloseStageDataType = {
  closedByUserName: string;
} & StageDataType;

export type CompleteConsentsStageDataType = {
  completedUserPreferences: number;
} & CloseStageDataType;

export interface UpdateStageReviewableFieldType {
  categories: string[];
  dataSource: string;
  dataSourceType: string;
  id: number;
  included: boolean;
  masked: boolean;
  name: string;
  fullObjectName: string;
  newValue?: string; // exist for UPDATE request type, NOT exist for DELETE request type
  friendlyName: string;
  purposes: string[];
  value: string;
}

export interface UpdateStageReviewableFieldsType {
  data: UpdateStageReviewableFieldType[];
  totalCount: number;
}

export interface ReviewableFieldType {
  id: string | number;
  friendlyName: string;
  name: string;
  dataSource: string;
  dataSourceType: string;
  purposes: string[];
  categories: string[];
  value: string;
  newValue?: string;
  masked: boolean;
  included: boolean;
  changed?: boolean;
}

export interface FindReviewableFieldsOptionsType {
  requestId: string;
  skip?: number;
  filter?: BigidMeFilterType[];
  limit?: number;
  included?: boolean;
  search?: string;
}

export interface UpdateReviewableFieldType {
  id: string | number;
  masked?: boolean;
  included?: boolean;
  name?: string;
  purposes?: string[];
  categories?: string[];
}

export type RequestFilter = {
  stages: ProcessingStageType[];
  types: RequestType[];
  regulations: RegulationType[];
  statuses: RequestStatusType[];
  submitFormNames: string[];
  brandNames: string[];
};

export enum ReviewableFieldsFilters {
  SYSTEM = 'dataSource.name',
  FRIENDLY_NAME = 'name',
  FIELD_NAME = 'originalName',
  PUPOSES = 'purposes.value',
  CATEGORIES = 'categories.value',
  INCLUDED = 'included',
  MASKED = 'masked',
}

export type ReportAttributeFilterOptionType = {
  value: string;
  label: string;
  isSelected: boolean;
};

export type ReportAttributeFilterType = {
  name: ReviewableFieldsFilters;
  options: ReportAttributeFilterOptionType[];
};

export const ReviewableFieldsFiltersNames = {
  [ReviewableFieldsFilters.SYSTEM]: 'System',
  [ReviewableFieldsFilters.FRIENDLY_NAME]: 'Field Friendly Name',
  [ReviewableFieldsFilters.FIELD_NAME]: 'Field Name',
  [ReviewableFieldsFilters.PUPOSES]: 'Purpose of use',
  [ReviewableFieldsFilters.CATEGORIES]: 'Category',
  [ReviewableFieldsFilters.INCLUDED]: 'Included in report',
  [ReviewableFieldsFilters.MASKED]: 'Mask',
};

export type ReviewableFieldsActionsType = {
  [ActionsType.PURPOSE]?: boolean;
  [ActionsType.FRIENDLY_NAME]?: boolean;
  [ActionsType.CATEGORY]?: boolean;
  [ActionsType.ADD_PURPOSE]?: boolean;
  [ActionsType.ADD_CATEGORY]?: boolean;
  [ActionsType.ADD_FRIENDLY_NAME]?: boolean;
  [ActionsType.EDIT]?: boolean;
};

export type NoteType = {
  actions: {
    [ActionsType.DELETE_NOTE]?: boolean;
    [ActionsType.UPDATE_NOTE]?: boolean;
  };
  id: string;
  content: string;
  userFullName: string;
  date: Date;
  dataSourceName: string;
  dataSourceType: string;
  files: FileType[];
  stage: string;
};

export type NoteCreateType = {
  content: string;
  files: Array<UploadedFileType | FileType>;
};

export type NoteUpdateType = NoteCreateType & {
  id: string;
};

export type SingleRequestStateType = {
  actions: {
    [ActionsType.READ_ACTIVITY_LOG]?: boolean;
    [ActionsType.SHOW_REQUEST_ATTRIBUTES]?: boolean;
    [ActionsType.VIEW_REQUESTOR_COMMUNICATIONS]?: boolean;
  };
};

export enum RequestDeletionMethod {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export enum ManagePreferencesStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  UPDATE_FAILED = 'UPDATE_FAILED',
  NA = 'NA',
}

export interface ReportTemplate {
  id: number;
  name: string;
}

export const managePreferencesStatusLabels = {
  [ManagePreferencesStatus.COMPLETED]: 'Completed',
  [ManagePreferencesStatus.IN_PROGRESS]: 'In Progress',
  [ManagePreferencesStatus.CONNECTION_ERROR]: 'Connection error',
  [ManagePreferencesStatus.USER_NOT_FOUND]: 'User not found',
  [ManagePreferencesStatus.UPDATE_FAILED]: 'Update failed',
  [ManagePreferencesStatus.NA]: 'N/A',
};

export enum ConsentFilters {
  CONSENT_TYPE = 'consentType',
  CATEGORY = 'category',
  DATASOURCE_NAME = 'dataSource.dataSourceName',
  SELECTED = 'selected',
  STATUS = 'status',
}

export const consentFiltersConfig = [
  {
    type: ConsentFilters.CONSENT_TYPE,
    label: 'Consent Type',
  },
  {
    type: ConsentFilters.CATEGORY,
    label: 'Category',
  },
  {
    type: ConsentFilters.DATASOURCE_NAME,
    label: 'System',
  },
  {
    type: ConsentFilters.SELECTED,
    label: 'Value',
  },
  {
    type: ConsentFilters.STATUS,
    label: 'Status',
  },
];

export type ConsentFiltersType = Record<ConsentFilters, string[]>;

export enum RecipientType {
  AUTHORIZED_AGENT = 'AUTHORIZED_AGENT',
  CONSUMER = 'CONSUMER',
  VENDOR = 'VENDOR',
  SYSTEM = 'SYSTEM',
  INTERNAL_USER = 'INTERNAL_USER',
  EXTERNAL = 'EXTERNAL',
}

export enum CommunicationType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export enum EmailStatusType {
  SENT = 'SENT',
  FAILED = 'FAILED',
  SENDING = 'SENDING',
}

export enum MessageType {
  FLOW = 'FLOW',
  INTERNAL_EXTERNAL = 'INTERNAL_EXTERNAL',
  VENDOR_REQUEST = 'VENDOR_REQUEST',
  VENDOR_RESPONSE = 'VENDOR_RESPONSE',
  CONTACT_REQUESTOR = 'CONTACT_REQUESTOR',
  CONTACT_REQUESTOR_RESPONSE = 'CONTACT_REQUESTOR_RESPONSE',
}

export type CommunicationThreadMessageType = {
  id: number;
  recipient: {
    type: RecipientType;
    name: string;
    email: string;
    vendorType?: string;
    vendorName?: string;
    vendorRequestId?: number;
    logoUrl?: string;
  };
  sender: {
    type: RecipientType;
    name: string;
    email?: string;
    vendorType?: string;
    vendorName?: string;
    vendorRequestId?: number;
    logoUrl?: string;
  };
  processingStage: ProcessingStageType;
  communicationType: CommunicationType;
  messageType: MessageType;
  subject: string;
  body: string;
  resolution?: string;
  comment?: string;
  removed?: boolean;
  files: [
    {
      id: number;
      fileName: string;
      removed?: boolean;
    },
  ];
  seen: boolean;
  status: EmailStatusType;
  statusDetails?: string;
  statusTimestamp: string; // iso,
  actions: {
    MARK_SEEN: boolean;
    MARK_UNSEEN: boolean;
    REPLY: boolean;
  };
};

export enum CommunicationThreadState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CommunicationThreadType = {
  filesCount: number;
  unseenMessagesCount: number;
  messages: CommunicationThreadMessageType[];
  state: CommunicationThreadState;
};

export type CommunicationDto = {
  threads: CommunicationThreadType[];
  unseenMessagesCount: number;
  missingDataDisclaimerEnabled?: boolean;
  communicationTypes: CommunicationType[];
  recipientTypes: RecipientType[];
  missingDataThresholdDate: string;
};

export type CommunicationMessageEvent = {
  requestId: string;
};

export type MessageFileDto = {
  id: number;
  fileName: string;
  contentType: string;
  removed: boolean;
};

export type RequestCloseResolutionType = {
  id: number;
  name: string;
  defaultForStage: boolean;
  attachments: {
    enabled: boolean;
    fileExtensions: Array<'.jpg' | '.jpeg' | '.png' | '.pdf' | '.csv'>;
    maxFileSizeMB: number;
    maxFiles: number;
  };
  type: ManualRequestResolutionsType;
  emailEnabled: boolean;
  emailTemplateId: number;
};

export type EmailStatusUpdatedResponseType = {
  requestId: string;
  emailCode: string;
  status: EmailStatusType;
};

export type ReviewableFieldOptionType = {
  id: number;
  value: string;
};
