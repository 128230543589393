import { useCallback, useState, useEffect } from 'react';
import { addBrand, deleteBrand, fetchBrands } from '../services/settingsService';
import { createContainer } from 'unstated-next';

import { BrandsGetType } from '../types';

export const DEFAULT_BRAND_IDX = 1;

const useBrands = () => {
  const [brands, setBrands] = useState<BrandsGetType>({ brands: [] });

  const loadBrands = useCallback(async () => {
    const brands = await fetchBrands();
    setBrands(brands);
  }, []);

  const addNewBrand = useCallback(async () => {
    const createdBrand = await addBrand();
    setBrands(state => ({ ...state, brands: [...state.brands, createdBrand] }));
    return createdBrand;
  }, []);

  const deleteBrandFromList = useCallback(async (brandId: number) => {
    await deleteBrand(brandId);
    setBrands(state => ({ ...state, brands: state.brands.filter(field => field.id !== brandId) }));
  }, []);

  useEffect(() => {
    loadBrands();
  }, [loadBrands]);

  return {
    brands,
    loadBrands,
    setBrands,
    addNewBrand,
    deleteBrandFromList,
  };
};

export const BrandsContainer = createContainer(useBrands);
