import React, { useEffect, useState, lazy, Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import {
  Route,
  Navigate,
  useLocation,
  createHashRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { Experimental_CssVarsProvider as CssVarsProvider, StyledEngineProvider } from '@mui/material/styles';
import { addLicense } from '@amcharts/amcharts4/core';
import { AppTheme } from './assets/theme';
import { RootWrapper } from './components/RootWrapper';
import { authService, putAuthSessionTimer } from './services/authentication';
import { Auth, AuthContainer } from './state/authContainer';
import { TutorialContainer } from './state/tutorialContainer';
import { PricingPlanContainer } from './state/pricingPlanContainer';
import { AnalyticsContainer } from './state/analyticsContainer';
import { getBusinessUser } from './services/user/userService';
import { MainLoader } from './components/MainLoader';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { PUBLIC_ROUTES, externalRouteMatcher } from './utils';
import RequestManager from './pages/RequestManager';
import {
  WorkflowCustomization,
  RequestWorkflowContainerComponent,
  WorkflowSettingsContainerComponent,
} from './pages/WorkflowCustomization';
import DashboardGuard from './components/DashboardGuard';
import { LoginError } from './components/LoginError';
import { Snackbar } from './components/Snackbar';
import OnboardingWindowGuard from './components/OnboardingWindowGuard';
import { NavItemType } from './types';
import NavItemActivationGuard from './components/NavItemActivationGuard';
import { activatePrivacyRequests } from './services/request';
import { NavItemsContainer } from './state/navItemsContainer';
import { BrandsContainer } from './state/brandsContainer';
import { NewFeatureContainer } from './state/newFeatureContainer';
import { IntlProvider } from 'react-intl';
import { AuthorizedAgentGuidedTour } from './components/AuthorizedAgentGuidedTour';
import { AppealGuidedTour } from './components/AppealGuidedTour';
import { AuthorizedAgentGuidedTourContainer } from './state/authorizedAgentGuidedTourContainer';
import { AppealGuidedTourContainer } from './state/appealGuidedTourContainer';
import { AvailableLanguagesContainer } from './state/availableLanguagesContainer';
import { AppSettingsContainer } from './state/appSettingsContainer';
import RoutePermissionGuard from './components/RoutePermissionGuard';
import { MonitoringContainer } from './state/monitoringContainer';
import { useBigidCoreLocationSync } from './hooks/useBigidCoreLocationSync';
import { BigidToastNotification } from '@bigid-ui/components';

const Brands = lazy(() => import('./pages/Brands'));
const VerificationNewAccountPage = lazy(() => import('./pages/VerificationNewAccountPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const LWCLoginPage = lazy(() => import('./pages/LWCLoginPage'));
const InviteConsent = lazy(() => import('./pages/InviteConsent'));
const PostLogin = lazy(() => import('./pages/PostLogin'));
const BasicSettings = lazy(() => import('./pages/BasicSettings'));
const JiraConfiguration = lazy(() => import('./pages/JiraConfiguration'));
const JiraConfigurationNew = lazy(() => import('./pages/JiraConfigurationNew'));
const JiraConfigurationUpdate = lazy(() => import('./pages/JiraConfigurationUpdate'));
const ManageSystems = lazy(() => import('./pages/ManageSystems'));
const SettingsSystem = lazy(() => import('./pages/SettingsSystem'));
const SettingsNewSystem = lazy(() => import('./pages/SettingsNewSystem'));
const ManageUsers = lazy(() => import('./pages/ManageUsers'));
const SettingsUser = lazy(() => import('./pages/SettingsUser'));
const ManageRoles = lazy(() => import('./pages/ManageRoles'));
const SettingsRole = lazy(() => import('./pages/SettingsRole'));
const ManageScopes = lazy(() => import('./pages/ManageScopes'));
const SettingsScope = lazy(() => import('./pages/SettingsScope'));
const SingleRequestAdapter = lazy(() => import('./pages/SingleRequest'));
const SettingsPublish = lazy(() => import('./pages/SettingsPublish'));
const PortalEditor = lazy(() => import('./pages/PortalEditor'));
const PricingGeneral = lazy(() => import('./pages/PricingGeneral'));
const Payment = lazy(() => import('./pages/Payment'));
const SignUp = lazy(() => import('./pages/SignUp'));
const DataSourceAssetsList = lazy(() => import('./pages/ConnectorAssetsList'));
const LoginErrorPage = lazy(() => import('./pages/LoginErrorPage'));
const PasswordChange = lazy(() => import('./pages/PasswordChange'));
const ConsentsMapping = lazy(() => import('./pages/ConsentsMapping'));
const CookieConsentLanding = lazy(() => import('./pages/CookieConsent/CookieConsentLanding'));
const PreferenceConsentsMapping = lazy(() => import('./pages/PreferenceConsentsMapping'));
const BasicSettingsPermissions = lazy(() => import('./pages/BasicSettingsPermissions'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const UnauthorizedPage = lazy(() => import('./pages/UnauthorizedPage'));

addLicense(process.env.REACT_APP_AMCHARTS_LICENSE!);
addLicense(process.env.REACT_APP_AMCHARTS_MAPS_LICENSE!);

enum MeRouteMode {
  GENERAL_LAYOUT,
  REQUIRE_AUTH,
}

export function App() {
  const [auth, setAuth] = useState<Auth>();

  useEffect(() => {
    if (!externalRouteMatcher(Object.values(PUBLIC_ROUTES))) {
      authenticateUser();
    } else {
      setAuth({
        isAuthenticated: false,
        fullName: 'public_user',
        tenants: [],
        id: 0,
      });
    }
  }, []);

  /*  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'hide');
    }
  }, []);*/

  const authenticateUser = async () => {
    putAuthSessionTimer();

    let auth: Auth = {
      isAuthenticated: false,
      fullName: '',
      id: 0,
      tenants: [],
    };

    let authenticated;
    try {
      authenticated = authService.isAuthenticated();
    } catch (e) {
      authenticated = false;
      console.log(e);
    }

    if (authenticated) {
      try {
        const user = await getBusinessUser(authService.getUser()!.id);
        auth = {
          isAuthenticated: true,
          fullName: user.fullName,
          id: user.id,
          tenants: user.tenants,
          currentTenant: authService.getUser()?.tenant,
        };
      } catch (e) {
        console.log(e);
      }
    }

    setAuth(auth);
  };

  const locale = navigator && navigator.language;

  const MeRouter = ({
    element,
    mode = MeRouteMode.GENERAL_LAYOUT,
    redirectTo = '/login',
  }: {
    element: JSX.Element;
    mode?: MeRouteMode;
    redirectTo?: string;
  }) => {
    const { auth } = AuthContainer.useContainer();
    const currentLocation = useLocation();
    useBigidCoreLocationSync();

    if (mode === MeRouteMode.REQUIRE_AUTH) {
      return auth.isAuthenticated ? (
        <ErrorBoundary>
          <NavItemsContainer.Provider>
            <BrandsContainer.Provider>
              <AnalyticsContainer.Provider>{element}</AnalyticsContainer.Provider>
            </BrandsContainer.Provider>
          </NavItemsContainer.Provider>
        </ErrorBoundary>
      ) : (
        <Navigate to={redirectTo} state={{ from: currentLocation }} />
      );
    }
    return auth.isAuthenticated ? (
      <ErrorBoundary>
        <NavItemsContainer.Provider>
          <AppSettingsContainer.Provider>
            <TutorialContainer.Provider>
              <PricingPlanContainer.Provider>
                <BrandsContainer.Provider>
                  <NewFeatureContainer.Provider>
                    <AuthorizedAgentGuidedTourContainer.Provider>
                      <AppealGuidedTourContainer.Provider>
                        <AnalyticsContainer.Provider>
                          <MonitoringContainer.Provider>
                            <AvailableLanguagesContainer.Provider>
                              <AppealGuidedTour />
                              <IntlProvider locale={locale} defaultLocale="en">
                                <RootWrapper>{element}</RootWrapper>
                              </IntlProvider>
                            </AvailableLanguagesContainer.Provider>
                          </MonitoringContainer.Provider>
                        </AnalyticsContainer.Provider>
                      </AppealGuidedTourContainer.Provider>
                    </AuthorizedAgentGuidedTourContainer.Provider>
                  </NewFeatureContainer.Provider>
                </BrandsContainer.Provider>
              </PricingPlanContainer.Provider>
            </TutorialContainer.Provider>
          </AppSettingsContainer.Provider>
        </NavItemsContainer.Provider>
        <BigidToastNotification />
      </ErrorBoundary>
    ) : (
      <Navigate to={redirectTo} state={{ from: currentLocation }} />
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={AppTheme}>
        <CssBaseline />
        {auth === undefined && <MainLoader />}
        {auth !== undefined && !auth?.hasError && (
          <AuthContainer.Provider initialState={auth}>
            <Snackbar>
              <Suspense fallback={<MainLoader />}>
                <RouterProvider
                  router={createHashRouter(
                    createRoutesFromElements(
                      <>
                        <Route path="/" element={<MeRouter element={<DashboardGuard />} />} />
                        <Route path={PUBLIC_ROUTES.EMAIL_VERIFICATION} element={<VerificationNewAccountPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/lwc-login" element={<LWCLoginPage />} />
                        <Route path="/welcome/:tenant/:accessToken" element={<InviteConsent />} />
                        <Route path="/post-login/:accessToken/:idToken" element={<PostLogin />} />
                        <Route path="/login-error/:errorCode?/:statusCode?" element={<LoginErrorPage />} />
                        <Route path="/password-change/:status" element={<PasswordChange />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/pricing-page" element={<PricingGeneral />} />
                        <Route
                          path="/pricing"
                          element={
                            <MeRouter
                              element={<PricingGeneral />}
                              mode={MeRouteMode.REQUIRE_AUTH}
                              redirectTo="/pricing-page"
                            />
                          }
                        />
                        <Route
                          path="/payment/:planName/:period/:product"
                          element={<MeRouter element={<Payment />} mode={MeRouteMode.REQUIRE_AUTH} />}
                        />
                        <Route
                          path="/request-manager/:id"
                          element={
                            <MeRouter
                              element={
                                <OnboardingWindowGuard>
                                  <ErrorBoundary>
                                    <SingleRequestAdapter />
                                  </ErrorBoundary>
                                </OnboardingWindowGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/request-manager"
                          element={
                            <MeRouter
                              element={
                                <NavItemActivationGuard
                                  itemType={NavItemType.OPEN_REQUESTS}
                                  activationCallback={activatePrivacyRequests}
                                >
                                  <OnboardingWindowGuard>
                                    <RequestManager />
                                  </OnboardingWindowGuard>
                                </NavItemActivationGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/brands"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.EDITOR}>
                                  <OnboardingWindowGuard>
                                    <Brands />
                                  </OnboardingWindowGuard>
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/editor/:brandId"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.EDITOR}>
                                  <OnboardingWindowGuard>
                                    <PortalEditor />
                                    <AuthorizedAgentGuidedTour />
                                  </OnboardingWindowGuard>
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/systems/new"
                          element={
                            <MeRouter
                              element={
                                <OnboardingWindowGuard>
                                  <SettingsNewSystem />
                                </OnboardingWindowGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/systems/:id"
                          element={
                            <MeRouter
                              element={
                                <OnboardingWindowGuard>
                                  <SettingsSystem />
                                </OnboardingWindowGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/systems"
                          element={
                            <MeRouter
                              element={
                                <OnboardingWindowGuard>
                                  <ManageSystems />
                                </OnboardingWindowGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/basic"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.BASIC_SETTINGS}>
                                  <BasicSettings />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/basic/permissions"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.BASIC_SETTINGS}>
                                  <BasicSettingsPermissions />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/basic/integrations/jira"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.BASIC_SETTINGS}>
                                  <JiraConfiguration />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/basic/integrations/jira/new"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.BASIC_SETTINGS}>
                                  <JiraConfigurationNew />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/basic/integrations/jira/:id"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.BASIC_SETTINGS}>
                                  <JiraConfigurationUpdate />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/users/:id"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.USERS}>
                                  <SettingsUser />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/users"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.USERS}>
                                  <ManageUsers />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/roles/:id"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.ROLES}>
                                  <SettingsRole />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/roles"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.ROLES}>
                                  <ManageRoles />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/scopes/:id"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.SCOPES}>
                                  <SettingsScope />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/scopes"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.SCOPES}>
                                  <ManageScopes />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/workflow-customization/workflow-settings/:requestTypeId"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.WORKFLOW_CUSTOMIZATION}>
                                  <WorkflowSettingsContainerComponent />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/workflow-customization/:requestTypeId"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.WORKFLOW_CUSTOMIZATION}>
                                  <RequestWorkflowContainerComponent />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/workflow-customization"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.WORKFLOW_CUSTOMIZATION}>
                                  <WorkflowCustomization />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/settings/publish"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.PUBLISH}>
                                  <SettingsPublish />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        <Route path="/settings" element={<Navigate to="/settings/basic" />} />
                        <Route path="/consents-mapping/new" element={<MeRouter element={<ConsentsMapping />} />} />
                        <Route
                          path="/consents-mapping/:id"
                          element={
                            <MeRouter
                              element={
                                <OnboardingWindowGuard>
                                  <PreferenceConsentsMapping />
                                </OnboardingWindowGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/consents-mapping"
                          element={
                            <MeRouter
                              element={
                                <OnboardingWindowGuard>
                                  <ConsentsMapping />
                                </OnboardingWindowGuard>
                              }
                            />
                          }
                        />
                        <Route
                          path="/cookie-consent"
                          element={
                            <MeRouter
                              element={
                                <RoutePermissionGuard itemType={NavItemType.COOKIE_CONSENT}>
                                  <CookieConsentLanding />
                                </RoutePermissionGuard>
                              }
                            />
                          }
                        />
                        {/* For test purposes only! */}
                        <Route path="/data-source-assets" element={<MeRouter element={<DataSourceAssetsList />} />} />
                        {/* Not found routes work as you'd expect */}
                        <Route path="/not-found" element={<MeRouter element={<NotFoundPage />} />} />
                        <Route path="/unauthorized" element={<MeRouter element={<UnauthorizedPage />} />} />
                        <Route path="*" element={<Navigate to="/not-found" replace={true} />} />
                      </>,
                    ),
                  )}
                />
              </Suspense>
            </Snackbar>
          </AuthContainer.Provider>
        )}
        {!auth?.isAuthenticated && auth?.hasError && <LoginError />}
      </CssVarsProvider>
    </StyledEngineProvider>
  );
}
