import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { BigidBIAnalyticsService, BigidBIAnalyticsServiceOptions } from '@bigid-ui/utils';
import { getAnalyticModuleByPath, isSmallIdApp, getUpdateRequestEventData } from '../utils';
import { AnalyticEventType, AnalyticsConfigType, RequestDataType, UpdateEventActionType } from '../types';
import { fetchAnalyticsConfig } from '../services/analyticsService';
import { isEmbeddedInIframe } from '../hooks/useEmbeddedApp';

class PortalAnalyticsService extends BigidBIAnalyticsService {
  setOptions(options: BigidBIAnalyticsServiceOptions) {
    this.options = options;
  }
}

const userGeneralProperties = [
  {
    property: 'screen_width',
    value: screen?.width,
  },
  {
    property: 'screen_height',
    value: screen?.height,
  },
  {
    property: 'screen_avail_height',
    value: screen?.availHeight,
  },
  {
    property: 'screen_avail_width',
    value: screen?.availWidth,
  },
  {
    property: 'screen_color_depth',
    value: screen?.colorDepth,
  },
  {
    property: 'screen_pixel_depth',
    value: screen?.pixelDepth,
  },
  {
    property: 'screen_orientation_type',
    value: screen?.orientation?.type,
  },
  {
    property: 'screen_orientation_angle',
    value: screen?.orientation?.angle,
  },
  {
    property: 'window_inner_width',
    value: window?.innerWidth,
  },
  {
    property: 'window_inner_height',
    value: window?.innerHeight,
  },
];

const analyticsService = new PortalAnalyticsService({ eventFixedData: [], moduleName: '' });
// there is problem with closures if store pathname inside useAnalytics
// you will get previous pathname in function for calculation module name
let pathname: string;

const useAnalytics = () => {
  const location = useLocation();
  const [config, setConfig] = useState<AnalyticsConfigType>();

  pathname = location.pathname;

  const trackUpdateRequestEvent = (request: RequestDataType, updateAction: UpdateEventActionType) => {
    analyticsService.trackManualEvent(
      AnalyticEventType.UpdateRequest,
      getUpdateRequestEventData(request, updateAction),
    );
  };

  const trackManualEvent = (eventType: string, eventData = {}) => {
    analyticsService.trackManualEvent(`PRIVACYPORTAL_${eventType.toUpperCase()}`, eventData);
  };

  useEffect(() => {
    const loadConfigs = async () => {
      const analyticsConfig = await fetchAnalyticsConfig();

      setConfig(analyticsConfig);
    };

    loadConfigs();
  }, []);

  useEffect(() => {
    const initAnalyticsService = () => {
      if (config?.enabled) {
        analyticsService.setOptions({
          userProperties: () => [
            { property: 'tenant', value: config.tenantId || '' },
            { property: 'company_name', value: config.companyName },
            { property: 'environment', value: config.environment },
            ...userGeneralProperties,
          ],
          eventFixedData: () => ({
            product_type: isSmallIdApp() ? 'SmallID' : 'Privacy Portal',
            isApp: isEmbeddedInIframe(),
            pathname,
          }),
          moduleName: () => getAnalyticModuleByPath(pathname),
        });
        analyticsService.setUserGroup('Accounts', config.companyName);
        analyticsService.init({
          apiKey: config.apiKey,
          serverUrl: config.serverUrl,
          userName: config.userId ? `privacyportal-user-${config.userId}` : 'no_user_id',
        });

        return () => {
          analyticsService.unregister();
        };
      }
    };

    return initAnalyticsService();
  }, [config]);

  useEffect(() => {
    if (!config?.enabled) {
      return;
    }
    const trackPageView = () => {
      const eventType = `${getAnalyticModuleByPath(location.pathname).toUpperCase()}`;
      analyticsService.trackPageView(eventType, {
        pathname: location.pathname,
        pageTitle: document.title,
      });
    };

    trackPageView();
  }, [config?.enabled, location.pathname]);

  return { analyticsService, trackUpdateRequestEvent, trackManualEvent };
};

export const AnalyticsContainer = createContainer(useAnalytics);
