import React, { FC } from 'react';
import { AuthorizedAgentGuidedTourStepTemplate } from './AuthorizedAgentGuidedTourStepTemplate';

interface BrandsEditStepProps {
  dataAid?: string;
}

export const BrandsEditStep: FC<BrandsEditStepProps> = ({ dataAid }) => {
  return (
    <AuthorizedAgentGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Select Brand"
      firstSectionText="Select the site where you want to enable the Authorized Agent flow."
    />
  );
};
