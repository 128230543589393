import { BigidGridRow } from '@bigid-ui/grid';
// eslint-disable-next-line import/no-unresolved
import { LanguageType } from '@consumer/state/languageContainer';
import { OnboardingRegulation, RequestSubmitFormType, RequestType } from '.';
import { ActionsType, ProcessingStageType } from './SingleRequestTypes';
import { ConsentDataSourceScanStatus } from './ConsentTypes';
import { DraggableItemData } from '@bigid-ui/components/lib/BigidDraggableList/types';
import { RequestDefinitionLayoutType } from '@consumer/services';
import { BigidMeSchemeCustomVars } from '@consumer/assets/theme';
import { BigidFieldFilterOperator } from '@bigid-ui/components/lib/BigidSideFilter/BigidSideFilter';
import { BigidDropdownOption } from '@bigid-ui/components';

export type SettingsLocalesType = {
  code: string;
  name: string;
  enabled: boolean;
  useAsDefault: boolean;
};

export type SettingsUpdateLocales = {
  code: string;
  enabled: boolean;
  useAsDefault: boolean;
};

export enum FormFieldType {
  TEXT = 'TEXT',
  PHONE = 'PHONE',
  COUNTRY = 'COUNTRY',
  USER_TYPE = 'USER_TYPE',
  COUNTRY_DIVISION = 'COUNTRY_DIVISION',
  DELIVERY_METHOD = 'DELIVERY_METHOD',
  DATE = 'DATE',
  SINGLE_SELECTION = 'SINGLE_SELECTION',
}

export type LocalizableFieldType = {
  locale?: string;
  label?: string;
  defaultValue?: string;
  hint?: string;
  tooltip?: string;
  options?: string[];
  title?: string;
  description?: string;
};

export type LocalizableUseCaseType = {
  locale: string;
  title?: string;
  description?: string;
};

export type LocalizableRequestorTypesType = {
  locale: string;
  title: string;
  description: string;
};

export type UpdateLocalizableUseCaseType = {
  id: number;
  enabled?: boolean;
  title?: string;
  description?: string;
  ordinal?: number;
};

export type FieldTranslationsType = Record<string, LocalizableFieldType>;
export type UseCaseTranslationsType = Record<string, LocalizableUseCaseType>;
export type RequestorTypesTranslationsType = Record<string, LocalizableRequestorTypesType>;

export type RequestGetSubmitFormsPreviewType = {
  portalUrl: string;
};

export type PublishedFormsType = {
  id: number;
  name: string;
  url: string;
};

export interface FormVerification {
  consumerVerificationId: number;
  consumerVerificationName?: string;
  verificationProvider: string;
  enabled: boolean;
}

export type NationalIdValidationOptions = {
  id: number;
  displayName: string;
  type: string;
};

export interface RequestSubmitFieldType {
  id: number;
  ordinal: number;
  valueRequired: boolean;
  enabled: boolean;
  name: string;
  label: string;
  canToggle: boolean;
  predefined: boolean;
  hideField: boolean;
  masked: boolean;
  type: FormFieldType;
  showTooltip: boolean;
  translations: FieldTranslationsType;
  requestSubmitFormId: number;
  dateFormat?: 'MM_DD_YYYY' | 'DD_MM_YYYY';
  enabledForPhysicalMail: boolean;
  valueRequiredForPhysicalMail: boolean;
  validationOptions?: NationalIdValidationOptions[];
  validationId?: number;
}

export interface DraggableRequestSubmitFieldType extends DraggableItemData {
  fields: RequestSubmitFieldType[];
}

export interface SettingsProfileType {
  id: number;
  enabled: boolean;
}

export interface SettingsProfileGetType extends SettingsProfileType {
  name: string;
  agentUserProfileUseCase?: {
    agentUserProfile?: string | null;
    useCase?: string | null;
  }[];
}

export interface SettingsProfileMappedType extends SettingsProfileType {
  name: string;
  agentUserProfile?: string | null;
  useCase?: string | null;
}

export interface BrandType {
  id: number;
  name: string;
  formId: number;
  creationDate: string;
  modificationDate: string;
  defaultBrand: boolean;
  requestSubmitFormCount: number;
  logoUrl?: string;
  siteName: string;
  siteUrl: string;
  actions: {
    [ActionsType.DELETE_BRAND]?: boolean;
    [ActionsType.UPDATE_BRAND]?: boolean;
  };
}

export interface BrandsGetType {
  brands: BrandType[];
  actions?: {
    [ActionsType.ADD_BRAND]?: boolean;
    [ActionsType.SHOW_PROFILES]?: boolean;
  };
}

export interface UserGridPreferenceViewFilter {
  id: number;
  name: string;
  enabled: boolean;
  ordinal: number;
  type: 'String' | 'Date';
  options: string[];
  operator: BigidFieldFilterOperator;
  currentRangeOption?: string;
  currentRangeMode?: string;
  dateFrom?: string;
  dateTo?: string;
}

export type UserGridPreferenceView = {
  columns: GridColumns[];
  filters: UserGridPreferenceViewFilter[];
  label: string;
  gridName: string;
  ordinal: number;
  predefined: boolean;
  id?: number;
};

export interface GridColumns {
  label: string;
  name: string;
  ordinal: number;
  enabled: boolean;
  sort?: string;
}
export interface UserPreferences {
  views: UserGridPreferenceView[];
}

export interface PreferencesGridFilter {
  label: string;
  name: string;
  type: 'String' | 'Date';
  source: string;
  searchable: boolean;
}

export type EmailSenderDataType<T = string> = {
  senderName: string;
  translations: Partial<Record<LanguageType, { footerText: T }>>;
};

export interface DatasourcesGridResponseType {
  dataSources: DatasourcesGridType[];
}

export interface DatasourcesGridType {
  dataSourceType: SystemDataSourceType;
  id: number;
  name: string;
  scopes: ScopeInfoType[];
  profiles: UserProfileInfoType[];
  connectionStatus: DatasourcesConnectionStatuses;
  connectionStatusUpdateTimestamp: string;
  collectingMethod: DatasourcesConnectionMethod;
  connectorType: DatasourcesConnectorType;
  stagedForDeletion?: boolean;
  updateRequired?: boolean;
  thirdParty?: boolean;
}

export interface DatasourcesRowType extends BigidGridRow {
  id: number;
  datasourceType: string;
  name: string;
  scopes: string[];
  profiles: string[];
  connectionStatus: DatasourcesConnectionStatuses;
  collectingMethod: DatasourcesConnectionMethod;
  connectorType?: DatasourcesConnectorType;
  connectionStatusUpdateTimestamp?: string;
  stagedForDeletion?: boolean;
  updateRequired?: boolean;
  thirdParty?: boolean;
}

//TODO: remove depricated
export enum DatasourcesConnectionStatuses {
  NONE = 'NONE',
  CONNECTED = 'CONNECTED',
  SIGN_IN = 'SIGN_IN',
  NOT_CONNECTED = 'NOT_CONNECTED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export enum DatasourcesProviderType {
  BIGID = 'BIGID',
  LWC = 'LWC',
  CUSTOM = 'CUSTOM',
}

export enum DatasourcesConnectionMethod {
  NONE = '',
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum DatasourcesConnectorType {
  AUTO_SCANNING = 'AUTO_SCANNING',
  DEEP_DISCOVERY = 'DEEP_DISCOVERY',
  WORKFLOW = 'WORKFLOW',
}

export type SignInResponseType = {
  authorizationUrl: string;
  externalId?: number;
  dataSourceTypeName?: string;
  dataSourceProviderType?: DatasourcesProviderType;
};

export type SignInValidationRequestType = {
  authId: string;
  dataSourceExternalId?: number;
  dataSourceTypeName?: string;
  dataSourceProviderType?: DatasourcesProviderType;
  supportedOperations?: SystemOperationType[];
};

export type SignInValidationResponseType = {
  authenticationId: string;
  valid: string;
};

export enum AccessesNameType {
  UPDATE = 'Update',
  COLLECT = 'Collect',
  DELETE = 'Delete',
  PREFERENCES = 'Preferences',
  All = 'All',
}

export type AccessesType = {
  accessName: AccessesNameType;
  selected: boolean;
  automatic: boolean;
};

export type SystemConnectionType = {
  externalId?: number;
  authId: string;
};

export type SystemAutoConsentType = {
  supported: boolean;
  connected: boolean;
  consentMetaScanStatus: ConsentDataSourceScanStatus;
  consentMetaScanDate?: string;
};

export type SystemInfoType = {
  accesses: AccessesType[];
  authorizationUrl: string;
  autoConsent?: SystemAutoConsentType;
  collectingMethod: DatasourcesConnectionMethod;
  connectionStatus: DatasourcesConnectionStatuses;
  connectionStatusUpdateTimestamp: string;
  connectorType?: DatasourcesConnectorType;
  connectorUserEmail: string;
  dataSourceType: SystemDataSourceType;
  externalId?: number;
  id: number;
  name: string;
  notifySystemTypeSupported?: boolean;
  profiles: SettingsProfileGetType[];
  scopes: DataSourceScopeType[];
  stagedForDeletion?: boolean;
  thirdParty?: boolean;
  updateRequired?: boolean;
  supportedOperations?: SystemOperationType[];
};

export type CreateDatasourseType = {
  id?: number;
  accesses?: string[];
  name: string;
  dataSourceTypeId?: number;
  dataSourceTypeName?: string;
  dataSourceTypeProviderType?: DatasourcesProviderType;
  dataSourceTypeSupported?: boolean;
  authId?: string;
  externalId?: number;
  scopeIds: string[];
  notifySystemTypeSupported?: boolean;
  profileIds?: string[];
  thirdParty?: boolean;
  collectingMethod?: DatasourcesConnectionMethod;
  connectionStatus?: DatasourcesConnectionStatuses;
  connectorType?: DatasourcesConnectorType;
  connectorUserEmail?: string;
  supportedOperations?: SystemOperationType[];
  dataSourceAccessDtos?: AccessesType[];
};

export type SystemRegisterStepFormType = {
  name: string;
  scopeIds: string[];
  profileIds?: string[];
  accesses?: string[];
  thirdParty?: boolean;
  dataSourceTypeName?: string;
  connectorType?: DatasourcesConnectorType;
};

export enum SystemOperationTypeType {
  TEST_CONNECTION = 'testConnection',
  COLLECT_REQUEST = 'asyncDsarRequest',
  DELETE_REQUEST = 'asyncDeleteRequest',
  GET_CONSENT_ITEMS_REQUEST = 'asyncGetConsentItemsRequest',
  GET_CONSENT_REQUEST = 'asyncGetConsentRequest',
  SET_CONSENT_REQUEST = 'asyncSetConsentRequest',
}

export type SystemOperationType = {
  type: SystemOperationTypeType;
  operationFlow: SystemOperationFlowType[];
};

export type SystemOperationFlowType = {
  request: {
    url: string;
    type: HttpRequestMethodType;
    headers: {
      name: string;
      value: string;
    }[];
    body: string;
  };
  response: {
    type: string;
  };
};

export type GenericConnectorPlaceHolder = {
  label: string;
  placeHolderName: string;
};

export interface RoleType {
  id: string;
  name: string;
}

export interface PermissionType {
  id: number;
  name: string;
  description: string;
  permissionName: string;
  enabled: number;
  total: number;
  dependsOn?: { id: number }[];
  permissionCategories?: PermissionType[];
  type?: string;
  parentId: number;
}

export interface PermissionGroupType {
  id: number;
  name: string;
  description: string;
  readOnly?: boolean;
  enabled: number;
  total: number;
  permissionCategories: PermissionType[];
}

export interface PermissionGroupInfoType {
  id: number;
  name: string;
  readOnly?: boolean;
  enabled: number;
  total: number;
}

export interface PermissionGroupListType {
  permissionGroups: PermissionGroupType[];
}

export type RoleOptionsDataType = {
  permissionGroups: PermissionGroupType[];
  scopes: DataSourceScopeType[];
};

export type UserInfoType = {
  firstName: string;
  lastName: string;
  id: number;
  email: string;
  roles: RoleType[];
  editableByPrivacyPortalUi?: boolean;
  actions?: { CHANGE_PASSWORD?: boolean };
};

export type UserPasswordType = {
  currentPassword: string;
  password: string;
};

export type UserScreenState = {
  actions: UserScreenActions;
};

export type UserScreenActions = {
  MANAGE_IDP?: boolean;
  READ_USER?: boolean;
  CREATE_USER?: boolean;
  UPDATE_USER?: boolean;
  DELETE_USER?: boolean;
};

export type UserApiKey = {
  creationDate: Date | string;
  expirationDate: Date | string;
  name: string;
  value: string;
  id: number;
};

export enum SettingsUserFieldsConfigType {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ROLE = 'roles',
}

export interface SystemDataSourceType {
  name: string;
  displayName: string;
  id: number;
  providerType: DatasourcesProviderType;
  supported: boolean;
}

export interface DataSourceSystemOnboardingTypes extends SystemDataSourceType {
  connectionId?: number;
  connectionStatus?: DatasourcesConnectionStatuses;
}

export interface DatasourcesResponseType {
  dataSourceTypes: SystemDataSourceType[];
}

export enum SystemFieldNames {
  NAME = 'name',
  DATA_SOURCE_NAME = 'dataSourceTypeName',
  SCOPE = 'scopeIds',
  USER_PROFILES = 'profileIds',
  ACCESSES = 'accesses',
  THIRD_PARTY = 'thirdParty',
}

export enum SystemsSorting {
  PRIORITY = 'priority',
  NAME = 'displayName',
}

export interface DataSourceScopeType {
  id: number;
  name: string;
  dataSourceTypes: string[];
  dataSources: string[];
}

export interface InstancesRowType extends BigidGridRow {
  name: string;
  datasourceType: string;
  datasourceTypeDisplayName: string;
}

export interface ScopeInfoType {
  id?: number;
  name: string;
}

export interface UserProfileInfoType {
  id?: number;
  name: string;
}

export interface RolesRowType extends BigidGridRow {
  name: string;
  permissionGroup?: RolePermissionGroupType;
  scope?: RoleScopeType;
}

export interface RolePermissionGroupType {
  id: number;
  name: string;
  description: string;
  root: boolean;
}

export interface RoleScopeType {
  id: number;
  name: string;
}

export interface RoleInfoType {
  id?: string;
  name: string;
  permissionGroupId: number;
  scopeId: number;
  editableByPrivacyPortalUi?: boolean;
}

export interface RoleInfoResponseType {
  id?: string;
  name: string;
  permissionGroup?: RolePermissionGroupType;
  scope?: RoleScopeType;
  editableByPrivacyPortalUi?: boolean;
}

export enum RoleInfoFieldsType {
  NAME = 'name',
  PERMISSION = 'permissionGroupId',
  SCOPE = 'scopeId',
}

export type RoleOptionsType = {
  [RoleInfoFieldsType.PERMISSION]: BigidDropdownOption[];
  [RoleInfoFieldsType.SCOPE]: BigidDropdownOption[];
};

export enum AddScopeStates {
  NAME_SCOPE,
  SELECT_INSTANCES,
}

export type PortalEditorGeneralTranslationsType = Partial<
  Record<
    LanguageType,
    {
      locale: string;
      headerTitle?: string;
      pageTitle?: string;
      pageSubtitle?: string;
      description?: string;
      copyright?: string;
    }
  >
>;

export enum RequestSubmitTextNameType {
  TOP_DISCLAIMER = 'topPrivacyDisclaimer',
  BOTTOM_DISCLAIMER = 'bottomPrivacyDisclaimer',
}

export enum RequestSubmitGeneralTextNameType {
  HEADER_TITLE = 'headerTitle',
  PAGE_TITLE = 'pageTitle',
  PAGE_SUBTITLE = 'pageSubtitle',
  DESCRIPTION = 'description',
  COPYRIGHT = 'copyright',
  SUPPORT_TITLE = 'supportTitle',
  SUPPORT_LINK = 'supportLink',
  PRIVACY_POLICY_TITLE = 'privacyPolicyTitle',
  PRIVACY_POLICY_LINK = 'privacyPolicyLink',
  TERMS_CONDITIONS_TITLE = 'termsConditionsTitle',
  TERMS_CONDITIONS_LINK = 'termsConditionsLink',
}

export type RequestSubmitTextType = {
  id: number;
  name: RequestSubmitTextNameType;
  label: string;
  enabled: boolean;
  translations: Partial<Record<LanguageType, { value: string }>>;
  requestSubmitFormId: number;
  disclaimerAdditionalData?: DisclaimerAdditionalDataType;
};

export type DisclaimerAdditionalDataType = {
  useBlueBackground: boolean;
  useIcon: boolean;
};

export type PreferencesSectionItemTranslationType = Partial<
  Record<LanguageType, { label: string; description?: string }>
>;

export type PreferencesSectionItemsType = {
  id: number | null;
  name: string;
  translations: PreferencesSectionItemTranslationType;
  isNew?: boolean;
  type?: PreferencesSectionChoiceType;
  selected?: boolean;
};

export type PreferencesSectionDictionaryType = {
  title: string;
  subtitle?: string;
  hint?: string;
  positive?: string;
  negative?: string;
  selectAllLabel?: string;
};

export type PreferencesSectionTranslationType = Partial<Record<LanguageType, PreferencesSectionDictionaryType>>;

export enum PreferencesSectionChoiceType {
  MULTI_SELECT = 'MULTI_SELECT',
  TOGGLE = 'TOGGLE',
  RADIO = 'RADIO',
  DROPDOWN = 'DROPDOWN',
  OPT_LIST = 'OPT_LIST',
}

export enum SectionDefaultType {
  NONE = 'NONE',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  CURRENT = 'CURRENT',
  PRESELECTED = 'PRESELECTED',
}

export type ExtraSectionDefaultType = SectionDefaultType | number;

export type PreferencesSectionType = {
  id: number;
  name: string;
  type: PreferencesSectionChoiceType;
  sectionOrder: number;
  selectAllEnabled?: boolean;
  predefined?: boolean;
  positiveEnabled?: boolean;
  negativeEnabled?: boolean;
  sectionDefault?: ExtraSectionDefaultType;
  enabled: boolean;
  translations: PreferencesSectionTranslationType;
  items: PreferencesSectionItemsType[];
};

export type PreferencesSectionDraggableType = DraggableItemData & {
  preferencesSections: PreferencesSectionType;
};

export type PreferencesStepType = {
  id: number;
  enabled: boolean;
  translations: Partial<Record<LanguageType, { title: string }>>;
  sections: PreferencesSectionType[];
};

export enum PreferencesStep {
  PREFERENCES,
  SURVEY,
}

export interface PortalEditorGetDataType {
  steps: PreferencesStepType[];
  optOuts: PreferencesStepType[];
  generalSettings: GeneralSettingsGetType;
  emailSettings: EmailSenderDataType;
  siteName: string;
}

export type PortalEditorFieldsType = {
  id: number;
  enabled: boolean;
  valueRequired: boolean;
};

export type PublishPortalPayloadType = {
  fields: PortalEditorFieldsType[];
  newFields: PortalEditorFieldsType[];
  deletedIds: number[];
  userProfiles: SettingsProfileGetType[];
  requestSubmitTexts: RequestSubmitTextType[];
  steps: PreferencesStepType[];
  optOuts: PreferencesStepType[];
  generalSettings: Partial<GeneralSettingsGetType>;
  emailSettings: EmailSenderDataType;
  formVerificationConfig: FormVerificationConfig[];
  forms: RequestSubmitFormType[];
  updateRequestorTypesDto: RequestorTypesGetType;
  updateBrandAuthorizedAgent: {
    fields: AuthorizedAgentFieldType[];
    texts: AuthorizedAgentTextType[];
    deletedFieldIds: number[];
    newFields: AuthorizedAgentFieldType[];
    translations: FieldTranslationsType;
    layout: RequestDefinitionLayoutType;
  };
};

export type FormVerificationConfig = {
  formId: number;
  consumerVerificationId: number;
};

export type PortalEditorPostDataType = {
  companyLogo: FormData | null;
  shouldDeleteLogo?: boolean;
  data: PublishPortalPayloadType;
};

export type GeneralSettingsGetType = {
  logoUrl: string;
  logoWidth: string | number;
  logoHeight: string | number;
  companyName: string;
  companyPhone: string;
  companyAddress: string;
  supportLink: string;
  termsConditionsLink: string;
  privacyPolicyLink: string;
  termsConditionsLinkEnabled: boolean;
  privacyPolicyLinkEnabled: boolean;
  copyrightEnabled: boolean;
  translations: PortalEditorGeneralTranslationsType;
  theme: BigidMeSchemeCustomVars;
};

export type PreviewTenantType = {
  isPreviewPP: boolean;
  tenantId: string;
  tenantName: string;
  logoUrl: string;
  logoWidth: string | number;
  logoHeight: string | number;
  supportLink: string;
  privacyPolicyLink: string;
  termsConditionsLink: string;
  translations: PortalEditorGeneralTranslationsType;
  copyright?: string;
  whitelabelBigidmeIcon?: boolean;
  theme: BigidMeSchemeCustomVars;
  analyticsConfig: {
    enabled: boolean;
    serverUrl: string;
    apiKey: string;
  };
};

export type BasicSettingsGetDataType = {
  state: BasicSettingsStateType;
  useCases: UseCasesType[];
  regulations: BaseSettingsRegulationType[];
  locales: SettingsLocalesType[];
  integrations: IntegrationGetType[];
  profiles: SettingsProfileGetType[];
};

export type BaseSettingsRegulationType = OnboardingRegulation & {
  requests: {
    [key in RequestType]: {
      id: string;
      dueDays: number | string;
      extensionDays: number | string; //for now is the same for View, Delete, Update and separate for Preferences, OptOut, Appeal
      extensionEnabled: boolean;
      defaultDueDays: number;
      defaultExtensionDays: number;
      defaultExtensionEnabled: boolean;
      defaultRequired: boolean;
      required: boolean;
    };
  };
};

export type IntegrationGetType = {
  id: number;
  name: string;
  label: string;
  description: string;
  type: IntegrationType;
  enabled: boolean;
  testConnectionSupported: boolean;
  configured: boolean;
  testConnectionResult?: TestConnectionResult;
};

export type InitVal = {
  tenantId: string;
  subscriber_preamble: string;
  requestType: string;
};

export type IntegrationDetailsGetType = {
  id: number;
  name: string;
  label: string;
  description: string;
  type: IntegrationType;
  testConnectionSupported: boolean;
  subSections: IntegrationSubSection[];
};

export type IntegrationAttribute = {
  id: number;
  name: string;
  label: string;
  tooltip: string;
  placeholder: string;
  type: string;
  storage: string;
  value: string;
  hasValue?: boolean;
  ordinal: string;
  masked: boolean;
  mandatory: boolean;
};

export type IntegrationSubSection = {
  id: number;
  name: string;
  label: string;
  integrationAttributes: IntegrationAttribute[];
};

export enum IntegrationType {
  DEFAULT = 'DEFAULT',
  VERIFICATION_METHOD_EXPERIAN = 'VERIFICATION_METHOD_EXPERIAN',
  VERIFICATION_METHOD_LEXIS_NEXIS = 'LEXIS_NEXIS',
  VERIFICATION_METHOD_REST = 'VERIFICATION_METHOD_REST',
  ENRICHMENT_METHOD_SAP = 'ENRICHMENT_METHOD_SAP',
  ENRICHMENT_METHOD_MYACCOUNT = 'ENRICHMENT_METHOD_MYACCOUNT',
  ENRICHMENT_METHOD_MYACCOUNT_OIDC = 'ENRICHMENT_METHOD_MYACCOUNT_OIDC',
  JIRA = 'JIRA',
}

export type IntegrationUpdateType = {
  id: number;
  name: string;
  enabled?: boolean;
  subSections: IntegrationUpdateSubSection[];
};

export type IntegrationUpdateSubSection = {
  id: number;
  name: string;
  integrationAttributes: IntegrationUpdateAttribute[];
};

export type IntegrationUpdateAttribute = {
  id: number;
  name: string;
  value: string;
};

export enum TestConnectionResponse {
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
}

export type TestConnectionResult = {
  testConnectionResponse: TestConnectionResponse;
  responseFromApi?: string;
};

export type IntegrationEnablingState = {
  id: number;
  enabled: boolean;
};

export enum IntegrationAttributeFieldType {
  TEXT = 'TEXT',
  FILE = 'FILE',
}

export enum BasicSettingsSectionNameType {
  REGULATIONS = 'regulations',
  LOCALES = 'locales',
  PROFILES = 'profiles',
  INTEGRATIONS = 'integrations',
}

export type BasicSettingsSectionType =
  | (BaseSettingsRegulationType | SettingsProfileGetType | SettingsLocalesType | IntegrationGetType)[]
  | AllowOutsideRequestorsType;

export type UseCaseType = {
  id: number;
  enabled: boolean;
  ordinal: number;
  translations: UseCaseTranslationsType;
  guideTourEnabled?: boolean;
};

export type UseCaseGetType = UseCaseType & UseCasesType;

export type UseCasesType = {
  type: RequestType;
};

export enum RequestorTypeType {
  SELF = 'SELF',
  AUTHORIZED_AGENT = 'AUTHORIZED_AGENT',
}

export type RequestorTypesGetType = {
  enabled: boolean;
  requestorTypes: RequestorTypeGetType[];
};

export type RequestorTypeGetType = {
  id: number;
  requestorType: RequestorTypeType;
  translations: RequestorTypesTranslationsType;
};

export type UseCaseDraggable = DraggableItemData & {
  useCase: UseCaseGetType;
};

export type PublishSubmitFormType = {
  submitFormUrl: string;
  edited: boolean;
  published: boolean;
};

export type CountryDivisionType = {
  code: string;
  name: string;
  type: string;
  displayName: string;
};

export type CountryType = {
  code: string;
  name: string;
  displayName: string;
  divisions: CountryDivisionType[];
};

export type SamlConfigurationType = {
  id: number;
  entityId: string;
  metadataFileContent?: string;
  metadataXmlFileLink?: string;
  samlPrivateKey?: string;
  samlPublicCert?: string;
  requestsSigned: boolean;
  enabled: boolean;
  loginCallback: string;
  logoutCallback: string;
};

export enum SamlMappingAttributeType {
  ATTRIBUTE = 'ATTRIBUTE',
  ROLE = 'ROLE',
}

export type SamlMappingType = {
  id: number;
  samlConfigurationId: number;
  keyAttribute: string;
  attributeType: SamlMappingAttributeType;
  tokenAttribute: string;
  valueAttribute?: string;
};

export type BasicSettingsStateType = {
  permissionsEnabled: boolean;
  securityConfigurationEnabled: boolean;
};
export interface AllowOutsideRequestorsType {
  allowOutsideRequestors: boolean;
  exceptionResidencies?: string[];
  onScreenMessage: OnScreenMessageType;
  emailMessageAvailable?: boolean;
  emailTemplate: UpdateEmailTemplateType;
}
export interface OnScreenMessageType {
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}
export enum SystemConnectionResult {
  SUCCESS,
  ERROR,
  PENDING,
}

export enum HttpRequestMethodType {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export enum WebhooksReasonType {
  STAGE_START = 'STAGE_START',
  STAGE_END = 'STAGE_END',
  MANUAL = 'MANUAL',
}

export interface WebhookType {
  id: number;
  name: string;
  trigger: WebhooksReasonType;
  enabled: boolean;
  method: HttpRequestMethodType;
  url: string;
  body: string;
  validateSsl: boolean;
  retryTimeout: string;
  modificationDate: string;
  headers: {
    name: string;
    value: string;
  }[];
  parameters: {
    name: string;
    value: string;
  }[];
}

export interface WebhookTestResponseType {
  httpStatusCode: number;
  response: string;
}

export interface WebhooksPlaceholdersGetType {
  placeholders: {
    value: string;
    label: string;
  }[];
}

export interface StageWebhooksGetType {
  webhooks: WebhookType[];
}

export interface RequestWorkflowStageType {
  id: number;
  name: ProcessingStageType;
  ordinal: number;
  enabled: boolean;
  emails: RequestWorkflowStageEmailType[];
  actions?: ActionsWorkflowGetType[];
  manualConfirmationRequired: boolean;
}

export interface ActionsWorkflowGetType {
  id: number;
  name: string;
  code: string;
  enabled: boolean;
  trigger: string;
  modificationDate?: string;
}

export interface VendorEngagement {
  id: number;
  name: string;
  vendorType: string;
  vendorName: string;
}

export interface RequestWorkflowStageEmailType {
  id: number;
  code: string;
  name: string;
  trigger: string;
  enabled: boolean;
  firstSendAfter?: string;
  modificationDate?: string;
  emailTemplateId: number;

  // added prop via confirm request modification
  disabled?: boolean;
  vendorEngagements?: VendorEngagement[];
  responseRequired?: boolean;
}

export type RequestWorkflowDefinitionDefaultCollaborators = {
  userFullName: string;
  userId: number;
};

export interface RequestWorkflowDefinitionGetType {
  id: number;
  workflowName: string;
  type: RequestType;

  name: string;
  userStatuses: string[];
  stages: RequestWorkflowStageType[];
  autoExtensionEnabled: boolean;
  modificationDate?: string;
  creationDate?: string;
  defaultOwnerUserId: number;
  defaultOwnerUserFullName: string;
  autoExtensionEmailTemplateId: number;
  extensionEmailEnabled: boolean;
  extensionAfterDays: number;
  reportRetentionPeriod: string;
  dataRetentionPeriod: string;
  defaultCollaborators: RequestWorkflowDefinitionDefaultCollaborators[];
}

export interface RequestWorkflowCustomizationDefinitionGetType {
  definitions: RequestWorkflowDefinitionGetType[];
}

export enum ManualRequestResolutionsType {
  REQUEST_TERMINATION = 'REQUEST_TERMINATION',
  REQUEST_COMPLETION = 'REQUEST_COMPLETION',
}

export type ResolutionType = ManualRequestResolutionsType | 'auto';

export interface ManualCloseResolutionsGetType {
  id: number;
  name: string;
  type: ManualRequestResolutionsType;
  code: string;
  defaultForStage: boolean;
  mandatory: boolean;
  emailEnabled: boolean;
  attachmentsEnabled: boolean;
  emailTemplateId: number;
  modificationDate?: string;
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}

export interface AutoCloseResolutionsGetType {
  id: number;
  name: string;
  text: string;
  code: string;
  emailTemplateId: number;
  emailEnabled: boolean;
  modificationDate?: string;
  mandatory: boolean;
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}

export interface CloseResolutionsGetType {
  resolutions: ManualCloseResolutionsGetType[] & AutoCloseResolutionsGetType[];
}

export type StageEmailConfigurationType = RequestWorkflowStageEmailType &
  ManualCloseResolutionsGetType &
  AutoCloseResolutionsGetType;

export type StageConfigType = 'general' | 'manual' | 'auto';
export interface UpdateWorkflowType {
  defaultOwnerUserId?: number;
  defaultOwnerUserFullName?: string;
  autoExtensionEnabled?: boolean;
  extensionEmailEnabled?: boolean;
  extensionAfterDays?: number;
  reportRetentionPeriod?: string;
  dataRetentionPeriod?: string;
  defaultCollaborators?: RequestWorkflowDefinitionDefaultCollaborators[];
}

export interface UpdateWorkflowStageType {
  enabled?: boolean;
  manualConfirmationRequired?: boolean;
}

export interface UpdateStageEmailType {
  enabled?: boolean;
  firstSendAfter?: string;
  vendorEngagements?: BigidDropdownOption[];
  responseRequired?: boolean;
}

export interface UpdateStageEmailPayloadType {
  enabled?: boolean;
  firstSendAfter?: string;
  vendorEngagementIds?: BigidDropdownOption[];
  responseRequired?: boolean;
}

export enum AuthorizedAgentMessageMode {
  AGENT = 'AGENT',
  CONSUMER = 'CONSUMER',
}

export interface EmailTemplateDataGetType {
  id: number;
  code: string;
  name: string;
  progressEnabled: boolean;
  appealEnabled?: boolean;
  authorizedAgentMessageMode?: AuthorizedAgentMessageMode;
  canToggleAppeal: boolean;
  predefined: boolean;
  translations: Partial<Record<LanguageType, EmailTemplateFieldsType>>;
}

// TODO: this type is using for 2 different form, separate
export type EmailTemplateFieldsType = {
  subject: string;
  header: string;
  title: string;
  message?: string;
  locale?: LanguageType;
  buttonText?: string;
  appealText?: string;
};

export interface UpdateEmailTemplateType {
  id?: number;
  progressEnabled: boolean;
  appealEnabled?: boolean;
  authorizedAgentMessageMode?: AuthorizedAgentMessageMode;
  canToggleAppeal?: boolean;
  translations: Partial<Record<LanguageType, EmailTemplateFieldsType>>;
}

export type WorkflowSettingsPostType = {
  configuration: UpdateWorkflowType;
  emailTemplate?: UpdateEmailTemplateType;
};

export type StageSettingsPostType = {
  configuration: UpdateStageEmailType;
  emailTemplate: UpdateEmailTemplateType;
};

export type ManualCloseResolutionsStageSettingsPostType = {
  configuration: ManualCloseResolutionsGetType;
  emailTemplate: UpdateEmailTemplateType;
};

export type AutoCloseResolutionsStageSettingsPostType = {
  configuration: AutoCloseResolutionsGetType;
  emailTemplate: UpdateEmailTemplateType;
};

export interface UpdateStageSettingsPostType {
  general: StageSettingsPostType;
  manual: ManualCloseResolutionsStageSettingsPostType;
  auto: AutoCloseResolutionsStageSettingsPostType;
  vendorDeletion?: {
    vendorEngagements?: BigidDropdownOption[];
    responseRequired?: boolean;
  };
}

export interface TestEmailType {
  locale: LanguageType;
  subject: string;
  header: string;
  title: string;
  message: string;
  buttonText: string;
  progressEnabled: boolean;
  appealEnabled?: boolean;
  authorizedAgentMessageMode?: AuthorizedAgentMessageMode;
  canToggleAppeal: boolean;
  emailCode?: string;
}

export interface AddManualCloseResolutionType {
  name: string;
  type: ManualRequestResolutionsType;
  defaultForStage: boolean;
  emailEnabled: boolean;
  requestStageId: number;
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}

export interface UpdateManualCloseResolutionsType {
  name: string;
  defaultForStage: boolean;
  emailEnabled: boolean;
  attachmentsEnabled: boolean;
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}

export interface AddAutoCloseResolutionType {
  requestId: number;
  name: string;
  text: string;
  emailEnabled: boolean;
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}

export interface UpdateAutoCloseResolutionsType {
  name: string;
  emailEnabled: boolean;
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}

export enum RequestWorkflowStageEmailCode {
  CONFIRM_SEPARATE_SECTION = 'common_email_verification_expired',
  CONFIRM_CONFIG_SECTION = 'common_email_verification',
  COMMON_REQUEST_RECEIVED = 'common_request_received',
  VENDOR_DELETION_REQUEST = 'vendor_deletion_request',
  ALLOW_OUTSIDE_REQUESTORS = 'allow_outside_requestors',
  COMMON_REQUEST_EXTENDED = 'common_request_extended',
  COMMON_REQUEST_IDENTITY_DECLINED = 'common_request_identity_declined',
  COMMON_REQUEST_CLOSED_V2 = 'common_request_closed_v2',
  COMMON_NOTIFICATION_V2 = 'common_notification_v2',
  COMMON_NEW_REQUEST_V2 = 'common_new_request_v2',
}

export enum AuthorizedAgents {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export enum AuthorizedAgentFormFieldType {
  TEXT = 'TEXT',
  PHONE = 'PHONE',
  COUNTRY = 'COUNTRY',
  USER_TYPE = 'USER_TYPE',
  COUNTRY_DIVISION = 'COUNTRY_DIVISION',
  SINGLE_SELECTION = 'SINGLE_SELECTION',
  AUTHORIZED_AGENT_TYPE = 'AUTHORIZED_AGENT_TYPE',
}

export enum AuthorizedAgentFieldNameType {
  AUTHORIZED_AGENT_TYPE = 'authorizedAgentType',
  AGENT_FIRST_NAME_INDIVIDUAL = 'agentFirstNameIndividual',
  AGENT_FIRST_NAME_BUSINESS = 'agentFirstNameBusiness',
  AGENT_LAST_NAME_INDIVIDUAL = 'agentLastNameIndividual',
  AGENT_LAST_NAME_BUSINESS = 'agentLastNameBusiness',
  EMAIL = 'email',
  MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY = 'country',
  STATE = 'state',
  BUSINESS_NAME = 'businessName',
  NATIONAL_Id = 'nationalId',
  UPLOAD_DOCUMENTS = 'uploadDocuments',
  BUSINESS_ENTITY_NUMBER = 'businessEntityNumber',
  DEFAULT = 'default',
}

export interface AuthorizedAgentFieldType {
  id: number;
  ordinal: number;
  enabled: boolean;
  name: AuthorizedAgentFieldNameType;
  label: string;
  canToggle: boolean;
  predefined: boolean;
  type: AuthorizedAgentFormFieldType;
  showTooltip: boolean;
  translations: FieldTranslationsType;
  showDescription: boolean;
  canChangeRequired: boolean;
  options?: {
    name: string;
    label: string;
    translations: FieldTranslationsType;
  }[];
  required: boolean;
  authorizedAgentType?: AuthorizedAgents;
  validationOptions?: NationalIdValidationOptions[];
  validationId?: number;
}

export type AuthorizedAgentTextType = {
  id: number;
  name: AuthorizedAgentTextNameType;
  label: string;
  enabled: boolean;
  translations: Partial<Record<LanguageType, { value: string }>>;
  requestSubmitFormId: number;
  useBlueBackground: boolean;
  useIcon: boolean;
};

export type AuthorizedAgentType = {
  fields: AuthorizedAgentFieldType[];
  texts: AuthorizedAgentTextType[];
  translations: FieldTranslationsType;
  layout: RequestDefinitionLayoutType;
};

export enum AuthorizedAgentTextNameType {
  TOP_DISCLAIMER = 'topPrivacyDisclaimer',
  BOTTOM_DISCLAIMER = 'bottomPrivacyDisclaimer',
}

export enum PasscodeType {
  NONE = 'NONE',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export interface RequestPasscodeType {
  type: PasscodeType;
  phoneNumberValidationEnabled: boolean;
}

export type JiraConfigurationFormType = {
  name: string;
  protocol: 'http' | 'https';
  host: string;
  userName: string;
  password: string;
  projectName: string;
  projectId: string;
  port: string;
  strictSsl: boolean;
};

export type DueDateNotificationsEmailTemplateFieldsType = {
  locale: LanguageType;
  subject: string;
  header: string;
  buttonText: string;
};

export type DueDateNotificationsSelectedRecipients = {
  userFullName: string;
  userId: number;
};

export enum DueDateNotificationsRecipient {
  OWNER_AND_COLLABORATORS = 'OwnersAndCollaborators',
  SELECTED_RECIPIENTS = 'Selected',
}

export type DueDateNotifications = {
  enabled: boolean;
  cron?: string;
  recipientType: DueDateNotificationsRecipient;
  selectedRecipients: DueDateNotificationsSelectedRecipients[];
  emailTemplate: {
    translations: Partial<Record<LanguageType, DueDateNotificationsEmailTemplateFieldsType>>;
  };
};

export type DueDateNotificationsUpdate = {
  enabled?: boolean;
  cron?: string;
  recipientType?: DueDateNotificationsRecipient;
  selectedRecipients?: DueDateNotificationsSelectedRecipients[];
  emailTemplate?: {
    translations: Partial<Record<LanguageType, DueDateNotificationsEmailTemplateFieldsType>>;
  };
};
