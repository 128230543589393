import React from 'react';
import { BigidDropdown, BigidDropdownOption } from '@bigid-ui/components';
import { GridRowType, GridActivityLogRowType } from '../types';
import { MeUpgradeButton } from '../components/MeUpgradeButton';
import { BigidSwitch } from '@bigid-ui/components';
import { StopPropagationContainer } from '../components/StopPropagationContainer';

export const renderMasked = (
  id: string | number,
  masked: boolean,
  onChange?: (id: string | number, checked: boolean) => void,
  disabled?: boolean,
) => (
  <StopPropagationContainer>
    <BigidSwitch
      id={String(id)}
      checked={!!masked}
      onChange={(_, checked) => {
        onChange && onChange(id, checked);
      }}
      dataAid={`mask-${id}`}
      disabled={disabled}
    />
  </StopPropagationContainer>
);

export const convertStringValueToBigidDropdownOptions = (value: string | string[], options: BigidDropdownOption[]) => {
  if (!value) {
    return [];
  }
  return (typeof value === 'string' ? [value] : value).map(
    v => options.find(option => option.value === v) || { id: v, displayValue: v, value: v },
  );
};

export const renderInlineEdit = ({
  id,
  name,
  dataAid,
  values,
  options,
  multi = true,
  onValueChange,
  onOptionAdd,
  onOptionDelete,
  enableAdd = true,
  isErasable = false,
  isSearchable = true,
  applyOnChange = false,
}: {
  id: number | string;
  name: string;
  dataAid?: string;
  values: string | string[];
  options: BigidDropdownOption[];
  multi?: boolean;
  onValueChange: ({ id, values, name }: { id: number | string; values: string | string[]; name: string }) => void;
  onOptionAdd?: (name: string, value: string) => void;
  onOptionDelete?: (options: { name: string; optionId: string }) => Promise<void>;
  enableAdd?: boolean;
  isErasable?: boolean;
  isSearchable?: boolean;
  applyOnChange?: boolean;
}) => {
  const transformedOptions = options.map(o => ({ id: o.id, displayValue: o.displayValue, value: o.value }));
  const selectedValue = convertStringValueToBigidDropdownOptions(values, transformedOptions);
  return (
    // div needed to prevent row clicking when selecting apply button in multi mode
    <StopPropagationContainer>
      <BigidDropdown
        dataAid={(dataAid || id)?.toString()}
        value={selectedValue}
        options={transformedOptions}
        onSelect={values =>
          onValueChange({ id, name, values: multi ? values.map(v => v.value) : values[0]?.value || '' })
        }
        onCreate={inputValue => {
          onOptionAdd?.(name, inputValue);
          return Promise.resolve({ value: inputValue, displayValue: inputValue, id: '' });
        }}
        onOptionDelete={
          enableAdd && onOptionDelete
            ? option => {
                return onOptionDelete({
                  name,
                  optionId: option.id,
                });
              }
            : undefined
        }
        isSearchable={isSearchable}
        isCreatable={enableAdd}
        isMulti={multi}
        isErasable={isErasable}
        applyOnChange={applyOnChange}
      />
    </StopPropagationContainer>
  );
};

export const formExtendDateRequestParams = (selectedRowIds: Array<string | number>) =>
  selectedRowIds.map(requestId => ({
    requestId: requestId.toString(),
  }));

export const getGridDefaultSorting = (field: string) => [
  {
    field,
    order: 'desc' as 'desc' | 'asc',
  },
];

export const getCellSortMarker = (
  sort: { field: Partial<GridRowType | GridActivityLogRowType> | string; order: 'asc' | 'desc' }[],
) => {
  if (sort.length === 1) {
    const { field, order } = sort[0];
    const sign = order === 'asc' ? '+' : '';
    if ('responseTime' === (field as string)) {
      return `${sign}responseActualDays,${sign}responseDeadlineDays`;
    }
    return `${sign}${field}`;
  }
  return [];
};

export const renderUpgradeButton = () => <MeUpgradeButton tooltip={`Upgrade to raise \nyour monthly request cap.`} />;
